import {
  Avatar,
  Box,
  Button,
  Chip,
  DialogContentText,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  TableCell,
  TableRow,
  useTheme,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { makeStyles } from "@material-ui/styles";
import { useFormik } from "formik";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import Datatable from "../../MuiComponent/Datatable";
import Modal from "../../MuiComponent/Modal";
import useGeneralStyle from "../../assets/css/general";
import { httpClient } from "../../config/httpClient";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import { useAuth } from "../../providers/AuthProvider";
import {
  selectCities,
  selectCountries,
  selectStates,
} from "../../store/selectors";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "../../MuiComponent/TextField";
import { useDispatch, useSelector } from "react-redux";
import { fetchCities, fetchCountries, fetchStates } from "../../store/actions";
import { convertBackendErrorResponse } from "../../utils/utility";
import AntSwitch from "../../components/AntSwitch";
import { hasAccess } from "../../menu";
import { PERMISSIONS } from "../../store";
import { useSnackbar } from "notistack";
import { managePolicyValidate } from "../../validations/login-validate";
import CloseIcon from "@material-ui/icons/Close";

const tableHeader = [
  {
    id: "date",
    label: "Registered Date",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "User Name",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "email",
    label: "EMAIL",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "phone",
    label: "PHONE",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "MANAGE",
    label: "Manage",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

const useStyles = makeStyles((theme2) => ({
  root: {
    width: "100%",
    marginTop: "25px",
    overflowX: "auto",
    backgroundColor: "white",
    border: "1px solid #caced5",
  },
  selectInput: {
    marginLeft: "20px",
    backgroundColor: "white",
    "& .MuiOutlinedInput-input": {
      padding: "12.5px 14px",
      width: "150px",
    },
  },
  container: {
    height: "440px",
    width: "100%",
  },
  activeFilter: {
    backgroundColor: theme2.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
  formControl: {
    margin: theme2.spacing(1),
    minWidth: 240,
  },
  formControlMenu: {
    margin: theme2.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme2.spacing(2),
  },
  input: {
    padding: "11px 14px",
  },
}));

function ServiceStaffDatatable() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const globalClasses = useGeneralStyle();
  const suplliersApiStatus = useCallbackStatus();
  const countriesList = useSelector(selectCountries);
  const statesList = useSelector(selectStates);
  const citiesList = useSelector(selectCities);
  const getSupllierApiStatus = useCallbackStatus();
  const { userData } = useAuth();
  const [search, setSearchKey] = useState("");
  const [modal, setModal] = useState(false);
  const [supplierData, setSupplierData] = useState([]);
  const [totalPages, setTotalPages] = React.useState(10);
  const [mode, setMode] = useState("add");
  const [policiesModal, setPoliciesModal] = useState(false);
  const managePolicyApiStatus = useCallbackStatus();
  const [policiesList, setPoliciesList] = useState([]);
  const notification = useSnackbar();
  const [userObj, setUserObject] = useState({});

  let {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    resetForm,
    setFieldValue,
    setValues,
    setErrors,
    submitForm,
  } = useFormik({
    initialValues: {
      name: "",
      last_name: "",
      email: "",
      contact_number: "",
      alternate_number: "",
      has_pos: false,
      profile_photo: "profile_photo",
      address: "",
      city: "",
      state: "",
      country: "",
      // aadhar_number: "",
      // aadhar_image: "",
    },
    onSubmit: () => {
      if (mode === "edit") {
        updateStaff();
        //   } else if (mode == "delete") {
        //     deleteCategory();
      } else {
        addData();
      }
    },
    // validationSchema: suplliersValidation,
  });

  const addData = async () => {
    await suplliersApiStatus.run(
      httpClient("POST", "/partner/addStaff", {
        body: values,
      })
        .then((result) => {
          fetchData();
          setModal(false);
          console.log("result", result);
        })
        .catch((er) => {
          setErrors(convertBackendErrorResponse(er.errors));
        })
    );
  };

  let getPoliciesList = useCallback(async () => {
    await managePolicyApiStatus.run(
      httpClient("POST", "partner/getAllPermissions", {
        body: {
          guard: "service",
        },
      })
        .then((result) => {
          const { data } = result;

          setPoliciesList(data);
        })
        .catch((error) => {
          notification.enqueueSnackbar(error.message, {
            variant: "error",
          });
        })
    );
  }, [notification, managePolicyApiStatus]);

  const fetchData = useCallback(
    async (pageSize = 10, pageNumber = 1) => {
      const apiBody = {
        page: pageNumber,
        page_size: pageSize,
        pagination_required: true,
        search_string: search,
        business_id: userData.business_id,
      };

      if (apiBody.search_string === "") {
        delete apiBody.search_string;
      }

      await getSupllierApiStatus.run(
        httpClient("POST", "/partner/fetchServiceStaff", {
          body: apiBody,
        })
          .then((result) => {
            console.log("Result", result);

            const {
              data: { data, meta },
            } = result;

            setSupplierData(data);
            setTotalPages(meta.total);
          })
          .catch((er) => {
            console.log("Error", er);
          })
      );
    },
    [search]
  );

  const updateStaff = async () => {
    await suplliersApiStatus.run(
      httpClient("POST", "/partner/updateStaffDetails", {
        body: values,
      })
        .then((result) => {
          console.log("Result", result);
          fetchData();
          setModal(false);
        })
        .catch((er) => {
          console.log("Error", er);
        })
    );
  };

  const addNewCategory = () => {
    setMode("add");
    setModal(false);
  };

  useEffect(() => {
    getPoliciesList();
    fetchData();
  }, [search]);

  const ModalHeader = () => {
    return (
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>{mode === "edit" ? "Edit" : "Add"} Service Staff</Box>
        {hasAccess(PERMISSIONS.POINT_OF_SALE) && (
          <Box textAlign="right">
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item style={{ fontSize: 16 }}>
                POS
              </Grid>
              <Grid item>
                <AntSwitch
                  checked={values.has_pos}
                  onChange={() => {
                    values.has_pos = !values.has_pos;
                    setFieldValue('has_pos',values.has_pos)
                  }}
                  name="checked"
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      // <Box padding={1}>{mode === "edit" ? "Edit" : "Add"} Service Staff</Box>
    );
  };

  const managePolicy = async () => {
    let newArr = manageFormik.values.policy.map((item) => item.id);
    let apiBody = {
      user_id: userObj.id,
      type: "service",
      permissions: newArr,
      roles: manageFormik.values.roles.map((item) => item.name),
    };

    await managePolicyApiStatus.run(
      httpClient("POST", "partner/syncPemissionAndRoleToUser", {
        body: apiBody,
      })
        .then((result) => {
          fetchData();
          setPoliciesModal(false);
        })
        .catch((err) => {
          notification.enqueueSnackbar(err.message, {
            variant: "err",
            autoHideDuration: 2000,
          });
        })
    );
  };

  const manageFormik = useFormik({
    initialValues: {
      roles: [],
      policy: [],
    },
    validationSchema: managePolicyValidate,

    onSubmit: () => {
      // manageStatus("add", values.title);
      managePolicy();
      manageFormik.resetForm();
    },
  });

  return (
    <>
      <Modal
        height="400px"
        width="100%"
        title={"Policies"}
        saveBtnText="Save"
        loading={managePolicyApiStatus.isPending}
        closeBtnText="Discard"
        closeHandler={() => {
          setPoliciesModal(!policiesModal);
          manageFormik.resetForm();
        }}
        saveHandler={() => {
          manageFormik.submitForm();
        }}
        status={policiesModal}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column">
                <InputLabel
                  className={globalClasses.inputLabel}
                  style={{ marginTop: "20px" }}
                >
                  Policies
                </InputLabel>
                <div>
                  <Autocomplete
                    multiple
                    // getOptionDisabled={(options) =>
                    //   values.policy.length == 3 ? true : false
                    // }
                    noOptionsText={<Box>No results found</Box>}
                    defaultValue={manageFormik.values.policy}
                    value={manageFormik.values.policy}
                    id="policy"
                    name="policy"
                    // id="tags-outlined"
                    // limitTags={3}`
                    options={policiesList.filter(
                      (elem) =>
                        !manageFormik.values.policy.find(
                          ({ id }) => elem.id === id
                        )
                    )}
                    onChange={(_, newValue) => {
                      manageFormik.setFieldValue("policy", newValue);
                    }}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    filterSelectedOptions
                    ChipProps={{
                      size: "small",
                      className: classes.languageChip,
                    }}
                    renderTags={() => {}}
                    size="small"
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Box>{option.name}</Box>
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={
                          manageFormik.touched.policy &&
                          manageFormik.errors.policy
                        }
                        helperText={
                          manageFormik.touched.policy &&
                          manageFormik.errors.policy
                        }
                        variant="outlined"
                        className="hoverhide"
                        // style={{
                        //   backgroundColor: "#303652",
                        //   color: "white",
                        //   height: "40px",
                        //   // background: "#212543",
                        // }}
                        placeholder="Select Policy"
                      />
                    )}
                  />
                  <Box>
                    {manageFormik.values.policy.map((item, index) => (
                      <Chip
                        style={{
                          color: "black",
                          marginRight: "8px",
                          borderRadius: "4px",
                          marginBottom: "5px",
                          marginTop: "5px",
                          borderRadius: "6px",
                          // border: "1px solid #E27851",
                          // backgroundColor: "#251E40",
                          color: "black",
                        }}
                        // style={{
                        //   height: "30px",
                        //   margin: "5px",
                        //   cursor: "default",
                        // }}
                        variant="outlined"
                        onDelete={() => {
                          manageFormik.setFieldValue(
                            "policy",
                            manageFormik.values.policy.filter(
                              (policy) => policy.id != item.id
                            )
                          );
                        }}
                        label={item.name}
                        deleteIcon={<CloseIcon style={{ height: "11px" }} />}
                      />
                    ))}
                  </Box>
                </div>
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>
      <Modal
        height="425px"
        width="650px"
        loading={suplliersApiStatus.isPending}
        headerComponent={ModalHeader}
        saveBtnText="Save"
        closeBtnText="Cancel"
        closeHandler={() => {
          resetForm();
          setModal(false);
        }}
        saveHandler={() => {
          submitForm();
        }}
        status={modal}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            {/* <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Avatar style={{ height: "70px", width: "70px" }} />
              </Box>
            </Grid> */}

            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Name
                </InputLabel>
                <TextField
                  id="name"
                  name="name"
                  error={touched.name && errors.name}
                  helperText={touched.name && errors.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="Name"
                  variant="outlined"
                  inputProps={{
                    style: {
                      padding: "10px",
                      fontSize: "12px",
                      backgroundColor: "white",
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Last Name
                </InputLabel>
                <TextField
                  id="last_name"
                  name="last_name"
                  error={touched.last_name && errors.last_name}
                  helperText={touched.last_name && errors.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.last_name}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="Name"
                  variant="outlined"
                  inputProps={{
                    style: {
                      padding: "10px",
                      fontSize: "12px",
                      backgroundColor: "white",
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Email
                </InputLabel>
                <TextField
                  id="email"
                  name="email"
                  error={touched.email && errors.email}
                  helperText={touched.email && errors.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="Name"
                  variant="outlined"
                  inputProps={{
                    style: {
                      padding: "10px",
                      fontSize: "12px",
                      backgroundColor: "white",
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Contact Number
                </InputLabel>
                <TextField
                  id="contact_number"
                  name="contact_number"
                  error={touched.contact_number && errors.contact_number}
                  helperText={touched.contact_number && errors.contact_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.contact_number}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="Contact Number"
                  variant="outlined"
                  inputProps={{
                    style: {
                      padding: "10px",
                      fontSize: "12px",
                      backgroundColor: "white",
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Alternate Number
                </InputLabel>
                <TextField
                  id="alternate_number"
                  name="alternate_number"
                  error={touched.alternate_number && errors.alternate_number}
                  helperText={
                    touched.alternate_number && errors.alternate_number
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.alternate_number}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="Alternate Number"
                  variant="outlined"
                  inputProps={{
                    style: {
                      padding: "10px",
                      fontSize: "12px",
                      backgroundColor: "white",
                    },
                  }}
                />
              </Box>
            </Grid>

            {/* <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Aadhar Number
                </InputLabel>
                <TextField
                  id="aadhar_number"
                  name="aadhar_number"
                  error={touched.aadhar_number && errors.aadhar_number}
                  helperText={touched.aadhar_number && errors.aadhar_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.aadhar_number}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="Aadhar Number"
                  variant="outlined"
                  inputProps={{
                    style: {
                      padding: "10px",
                      fontSize: "12px",
                      backgroundColor: "white",
                    },
                  }}
                />
              </Box>
            </Grid> */}

            {/* <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Aadhar Image
                </InputLabel>
                <TextField
                  id="aadhar_image"
                  name="aadhar_image"
                  error={touched.aadhar_image && errors.aadhar_image}
                  helperText={touched.aadhar_image && errors.aadhar_image}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.aadhar_image}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="Aadhar Image"
                  variant="outlined"
                  inputProps={{
                    style: {
                      padding: "10px",
                      fontSize: "12px",
                      backgroundColor: "white",
                    },
                  }}
                />
              </Box>
            </Grid> */}

            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Address
                </InputLabel>
                <TextField
                  id="address"
                  name="address"
                  error={touched.address && errors.address}
                  helperText={touched.address && errors.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="Address"
                  variant="outlined"
                  inputProps={{
                    style: {
                      padding: "10px",
                      fontSize: "12px",
                      backgroundColor: "white",
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Country
                </InputLabel>

                <Autocomplete
                  classes={{ inputRoot: classes.inputRootAutoComplete }}
                  id="combo-box-demo"
                  defaultValue={values.state}
                  value={countriesList.find(
                    (country) => country.id == values.country
                  )}
                  options={countriesList}
                  onChange={(event, newValue) => {
                    setFieldValue("country", newValue?.id);
                    dispatch(
                      fetchStates({
                        country: newValue?.id,
                      })
                    );
                  }}
                  getOptionLabel={(option) => option.name}
                  style={{ height: "50px" }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        placeholder="Select Country"
                        error={touched.country && errors.country}
                        helperText={touched.country && errors.country}
                        onChange={(event) => {
                          //setSearchKey(event.target.value);
                        }}
                        variant="outlined"
                      />
                    );
                  }}
                />

                {/* <TextField
                  id="country"
                  name="country"
                  error={touched.country && errors.country}
                  helperText={touched.country && errors.country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.country}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="write here"
                  variant="outlined"
                  inputProps={{
                    style: {
                      padding: "10px",
                      fontSize: "12px",
                      backgroundColor: "white",
                    },
                  }}
                /> */}
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  State
                </InputLabel>
                {/* <TextField
                  id="state"
                  name="state"
                  error={touched.state && errors.state}
                  helperText={touched.state && errors.state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.state}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="state"
                  variant="outlined"
                  inputProps={{
                    style: {
                      padding: "10px",
                      fontSize: "12px",
                      backgroundColor: "white",
                    },
                  }}
                /> */}
                {/* {typeof statesList.find((state) => state.id == values.state) !=
                  "undefined" && ( */}
                <Autocomplete
                  classes={{ inputRoot: classes.inputRootAutoComplete }}
                  id="combo-box-demo"
                  defaultValue={values.state}
                  value={statesList.find((state) => state.id == values.state)}
                  options={statesList}
                  onChange={(event, newValue) => {
                    setFieldValue("state", newValue?.id);
                    dispatch(
                      fetchCities({
                        state_id: newValue?.id,
                      })
                    );
                  }}
                  getOptionLabel={(option) => option.name}
                  style={{ height: "50px" }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        placeholder="Select State"
                        error={touched.state && errors.state}
                        helperText={touched.state && errors.state}
                        onChange={(event) => {
                          //setSearchKey(event.target.value);
                        }}
                        variant="outlined"
                      />
                    );
                  }}
                />
                {/* )} */}
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  City
                </InputLabel>
                {/* {typeof citiesList.find((city) => city.id == values.city) !=
                  "undefined" && ( */}
                <Autocomplete
                  classes={{ inputRoot: classes.inputRootAutoComplete }}
                  id="combo-box-demo"
                  defaultValue={values.city}
                  value={citiesList.find((city) => city.id == values.city)}
                  options={citiesList}
                  onChange={(event, newValue) => {
                    setFieldValue("city", newValue?.id);
                  }}
                  getOptionLabel={(option) => option.name}
                  style={{ height: "50px" }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        placeholder="Select State"
                        error={touched.city && errors.city}
                        helperText={touched.city && errors.city}
                        onChange={(event) => {
                          //setSearchKey(event.target.value);
                        }}
                        variant="outlined"
                      />
                    );
                  }}
                />
                {/* )} */}
                {/* <TextField
                  id="city"
                  name="city"
                  error={touched.city && errors.city}
                  helperText={touched.city && errors.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city}
                  FormHelperTextProps={{ style: { fontSize: "10px" } }}
                  placeholder="city"
                  variant="outlined"
                  inputProps={{
                    style: {
                      padding: "10px",
                      fontSize: "12px",
                      backgroundColor: "white",
                    },
                  }}
                /> */}
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>

      <Grid container style={{ padding: theme.spacing(3) }}>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px 0px",
          }}
          item
          xs={12}
          sm={4}
        >
          <Box style={{ paddingRight: "20px" }}>Search</Box>
          <TextField
            variant="outlined"
            onChange={(e) => setSearchKey(e.target.value)}
            placeholder="by Name"
            inputProps={{
              style: { padding: "12px", backgroundColor: "white" },
            }}
          />
        </Grid>

        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row-reverse",
            padding: "10px 0px",
          }}
          item
          xs={12}
          sm={8}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              addNewCategory();
              setModal(true);
              setMode("add");
            }}
          >
            Add Service Staff
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.root}>
            <Datatable
              loading={getSupllierApiStatus.isPending}
              getDataList={fetchData}
              totalRecords={totalPages}
              tableHeader={tableHeader}
            >
              {supplierData.map((data) => {
                return (
                  <>
                    <TableRow>
                      <TableCell>
                        {moment(data.created_at).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell>{data.name}</TableCell>
                      <TableCell>{data.email}</TableCell>
                      <TableCell>{data.contact_number}</TableCell>
                      <TableCell>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <React.Fragment>
                              <IconButton {...bindTrigger(popupState)}>
                                <MoreHorizIcon />
                              </IconButton>
                              <Menu {...bindMenu(popupState)}>
                                <MenuItem
                                  onClick={() => {
                                    setValues(data);
                                    setMode("edit");
                                    setModal(true);
                                    popupState.setOpen(false);
                                  }}
                                >
                                  Edit
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    setUserObject(data);
                                    manageFormik.setFieldValue(
                                      "policy",
                                      data.direct_permissions
                                    );

                                    popupState.setOpen(false);
                                    setPoliciesModal(!policiesModal);
                                  }}
                                >
                                  Permissions
                                </MenuItem>
                                {/* <MenuItem
                                  onClick={() => {
                                    setValues({
                                      //   email: userData.email,
                                      // role: userData.role,
                                    });
                                    popupState.setOpen(false);
                                    // setMode("delete");
                                    // setConfirmation(true);
                                    // popupState.setOpen(false);
                                  }}
                                >
                                  Delete
                                </MenuItem> */}
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </Datatable>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default ServiceStaffDatatable;
