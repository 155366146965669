import { createSelector } from "@reduxjs/toolkit";

export const selectCountries = (state) => state.general.countries;
export const selectStates = (state) => state.general.states;
export const selectCities = (state) => state.general.cities;

export const selectGeneralReducer = (state) => state.general;
export const selectCategoryReducer = (state) => state.category;

export const selectBillingReducer = (state) => state.billing;
export const selectOrderData = createSelector(selectGeneralReducer, (state) => {
  return state.billData;
});

export const selectLastModifiedRecord = (type) => {
  return createSelector(selectGeneralReducer, (state) => {
    return state?.userData.businessDetails.last_modified.find(data=>data.table===type);
  });
};

export const selectCache = (table) => {
  return createSelector(selectGeneralReducer, (state) => {
    return state.cache[table];
  });
};

export const selectLoadingState = (type) => {
  return createSelector(selectGeneralReducer, (state) => {
    return state?.loaders[type];
  });
};

export const selectCategoryList = createSelector(
  selectCategoryReducer,
  (state) => state.categories
);

export const selectPurchaseData = createSelector(
  selectCategoryReducer,
  (state) => state.purchases
);

export const selectProductOfferData = createSelector(
  selectCategoryReducer,
  (state) => state.product_offers
);

export const selectPOSData = createSelector(
  selectCategoryReducer,
  (state) => state.pos
);

export const selectInventoryData = createSelector(
  selectCategoryReducer,
  (state) => state.inventory
);

export const selectSuppliers = createSelector(
  selectCategoryReducer,
  (state) => state.suppliers
);

export const selectOrders = createSelector(
  selectCategoryReducer,
  (state) => state.orders
);

export const selectFormValidation = createSelector(
  selectGeneralReducer,
  (state) => state.form_validations
);

export const selectCollectedOrders = createSelector(
  selectGeneralReducer,
  (state) => state.collected_orders
);

export const selectUsersList = createSelector(
  selectGeneralReducer,
  (state) => state.users_list
);

export const selectProducts = createSelector(
  selectGeneralReducer,
  (state) => state.products
);

export const selectDatatableProductList = createSelector(
  selectCategoryReducer,
  (state) => state.products
);

export const selectModalStatus = createSelector(
  selectGeneralReducer,
  (state) => state.modal_status
);

export const selectServiceCustomer = createSelector(
  selectGeneralReducer,
  (state) => state.service_customers
);

export const selectSlots = createSelector(
  selectGeneralReducer,
  (state) => state.slots
);

export const selectFormStructure = (table_name) => {
  return createSelector(
    selectGeneralReducer,
    (state) => state.form_structure[table_name]
  );
};

export const selectServiceBills = createSelector(
  selectBillingReducer,
  (state) => state.service_bills
);

export const selectBillableCustomer = createSelector(
  selectBillingReducer,
  (state) => state.customer_data
);
