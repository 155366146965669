import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useFormik } from "formik";
import _ from "lodash";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { useSnackbar } from "notistack";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Modal from "../../MuiComponent/Modal";
import ReactTable from "../../MuiComponent/ReactTable";
import TextField from "../../MuiComponent/TextField";
import useGeneralStyle from "../../assets/css/general";
import { apiClient } from "../../config/apiClient";
import { httpClient } from "../../config/httpClient";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import useCancelRequest from "../../hooks/useCancelToken";
import { useAuth } from "../../providers/AuthProvider";
import { selectLoadingState, selectUsersList } from "../../store/selectors";
import { fetchAllUsersData } from "../../store/user/actions";
import { posFormValidation } from "../../validations/login-validate";
import queryString from "query-string";

const useStyles = makeStyles((theme2) => ({
  root: {
    width: "100%",
    marginTop: "25px",
    overflowX: "auto",
    backgroundColor: "white",
    border: "1px solid #caced5",
  },
  selectInput: {
    marginLeft: "20px",
    "& .MuiOutlinedInput-input": {
      padding: "12.5px 14px",
      width: "150px",
    },
  },
  container: {
    height: "440px",
    width: "100%",
  },
  activeFilter: {
    backgroundColor: theme2.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
}));

export default function POSDataTable({ userType }) {
  const classes = useStyles();
  const tableRef = useRef();
  const dispatch = useDispatch();

  const [modal, setModal] = React.useState(false);
  const globalClasses = useGeneralStyle();
  const tableLoader = useSelector(selectLoadingState("react_table_loader"));
  const { data: usersList, meta } = useSelector(selectUsersList);
  const manageUserApiStatus = useCallbackStatus();
  const auth = useAuth();
  const history = useHistory();
  const [filter, setFilter] = useState({
    search_string: null,
  });

  const theme = useTheme();

  const columns = useMemo(() => {
    return [
      {
        header: "REGISTERED DATE",
        key: "created_at",
        type: "date",
        dateFormat: "DD MMM YYYY, hh:mm a",
      },
      {
        header: "USER NAME",
        type: "custom_cell",
        cell: (info) => `${info.row.original.name}`,
      },
      { header: "MOBILE", key: "mobile_number" },
      { header: "EMAIL", key: "name" },
      {
        header: "MANAGE",
        type: "custom_cell",
        cell: (info) => {
          return (
            <>
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <React.Fragment>
                    <IconButton {...bindTrigger(popupState)}>
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        onClick={() => {
                          setValues(info.row.original);
                          setModal(!modal);
                          popupState.setOpen(false);
                        }}
                      >
                        Edit
                      </MenuItem>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
            </>
          );
        },
      },
    ];
  }, [filter]);

  const {
    touched,
    values,
    initialValues,
    errors,
    validateForm,
    resetForm,
    setValues,
    setFieldValue,
    submitForm,
    handleChange,
  } = useFormik({
    initialValues: {
      name: "",
      mobile_number: "",
      email: "",
    },
    validationSchema: posFormValidation,
    onSubmit: () => {
      updateData();
    },
  });

  const ModalHeader = () => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ fontSize: "14px" }}
        >
          <Box textAlign="left" style={{ fontWeight: 600 }} padding={1}>
            Add Bill
          </Box>
          <IconButton
            style={{ position: "absolute", right: "20px" }}
            onClick={() => {
              setModal(!modal);
            }}
          >
            <CloseIcon size="small" />
          </IconButton>
        </Box>
      </>
    );
  };

  const updateData = async () => {
    await manageUserApiStatus.run(
      httpClient("PUT", `/partner/updateWalkinCustomerProfile/${values.id}`, {
        body: values,
      }).then((result) => {
        resetForm();
        setModal(!modal);
        getAllUserList();
        console.log("Result: ", result);
      })
    );
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      tableRef.current.refreshDatatable();
    }, 300);

    Object.keys(filter).forEach((key) => {
      if (filter[key] == null || filter[key] == "") {
        delete filter[key];
      }
    });

    history.replace(location.pathname + "?" + `${new URLSearchParams(filter)}`);

    return () => clearTimeout(delayDebounceFn);
  }, [filter.search_string]);

  const globalSearchHandler = useCallback((e) => {
    let params = queryString.parse(location.search);
    params.search_string = e.target.value;

    filter.search_string = e.target.value;
    setFilter({ ...filter });
  }, []);

  const getTableData = useCallback(
    (apiBody) => {
      console.log(
        "REACT TABLE PAGE INDEX MANUAL PAGINATION: GET API INVOKED: ",
        apiBody
      );

      dispatch(
        fetchAllUsersData({
          ...apiBody,
          user_type: "walkin",
          business_id: auth.userData.business_id,
        })
      );
    },
    [dispatch]
  );

  return (
    <>
      <Modal
        headerComponent={ModalHeader}
        height="350px"
        width="600px"
        loading={manageUserApiStatus.isPending}
        closeBtnText="Cancel"
        saveBtnText="Update"
        saveHandler={submitForm}
        closeHandler={() => {
          setModal(!modal);
        }}
        status={modal}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box style={{ padding: "10px 0px" }}>Name</Box>
            <TextField
              name="name"
              onChange={handleChange}
              placeholder="Write Here"
              value={values.name}
              error={touched.name && errors.name ? true : false}
              helperText={touched.name && errors.name}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <Box style={{ padding: "10px 0px" }}>Contact Number</Box>
            <TextField
              name="mobile_number"
              onChange={handleChange}
              error={
                touched.mobile_number && errors.mobile_number ? true : false
              }
              helperText={touched.mobile_number && errors.mobile_number}
              value={values.mobile_number}
              placeholder="Write Here"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <Box style={{ padding: "10px 0px" }}>Email</Box>
            <TextField
              name="email"
              onChange={handleChange}
              value={values.email}
              error={touched.email && errors.email ? true : false}
              helperText={touched.email && errors.email}
              placeholder="Write Here"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Modal>
      <Grid container style={{ padding: theme.spacing(3) }}>
        <Grid
          style={{ display: "flex", alignItems: "center", padding: "10px 0px" }}
          item
          xs={12}
          md={4}
        >
          <Box
            className={globalClasses.filterLabel}
            style={{ paddingRight: "20px" }}
          >
            Search
          </Box>
          <TextField
            value={filter.search_string}
            onChange={globalSearchHandler}
            variant="outlined"
            style={{ width: "100%" }}
            inputProps={{
              style: { padding: "12px", backgroundColor: "white" },
            }}
            placeholder="by Name & Mobile Number"
          />
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <ReactTable
              ref={tableRef}
              fetchData={getTableData}
              isLoading={tableLoader}
              totalRecords={meta?.total}
              columns={columns}
              data={usersList || []}
              manualPagination={true}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
