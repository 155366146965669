import DateFnsUtils from "@date-io/moment";
import { Button, Grid, InputLabel, makeStyles } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import React from "react";
import DefaultLoader from "../layouts/DefaultLoader";
import TextField from "./TextField";

const useStyles = makeStyles(() => ({
  inputLabel: {
    color: "black",
    fontSize: "14px",
    padding: "10px 0px",
  },
  inputRootAutoComplete: {
    padding: "0px 8px !important",
  },
}));

function DynamicForm({
  formData,
  loading,
  isLogicInComponent,
  children,
  productList,
  formik,
}) {
  const classes = useStyles();
  let {
    values,
    touched,
    errors,
    setValues,
    setErrors,
    setFieldValue,
    resetForm,
    handleBlur,
    submitForm,
    handleChange,
  } = formik;

  //   const handleInput = (e) => {
  //     setFieldValue(field.name, e.target.value);
  //   };

  const renderSelect = (field) => {
    if (field.internal_data) {
      return (
        <>
          {loading.productList == false ? (
            <Autocomplete
              classes={{ inputRoot: classes.inputRootAutoComplete }}
              name={`form_input.${field.name}`}
              id={`form_input.${field.name}`}
              options={productList}
              getOptionLabel={(option) => option.name}
              value={productList.find(
                (product) => product.id == values?.form_input[field.name]
              )}
              onChange={(e, selectedValue) => {
                console.log(
                  "SELECTED_ID:",
                  field.name,
                  selectedValue,
                  `form_input.${field.name}`
                );
                setFieldValue(
                  `form_input.${field.name}`,
                  selectedValue?.id ? selectedValue?.id : ""
                );
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    placeholder={`Select ${field.label}`}
                    error={errors?.form_input && errors?.form_input[field.name]}
                    helperText={
                      errors?.form_input && errors?.form_input[field.name]
                        ? errors?.form_input[field.name]
                        : ""
                    }
                    onChange={(event) => {
                      // setSearchProductKey(event.target.value);
                    }}
                    variant="outlined"
                  />
                );
              }}
            />
          ) : (
            <DefaultLoader />
          )}
        </>
      );
    } else
      return (
        <>
          <Autocomplete
            defaultValue={field.enum.find(
              (option) => option.value == field.defaultValue
            )}
            value={field.enum.find(
              (option) => option.value == values?.form_input[field.name]
            )}
            classes={{ inputRoot: classes.inputRootAutoComplete }}
            name={`form_input.${field.name}`}
            id={`form_input.${field.name}`}
            options={field.enum}
            getOptionLabel={(option) => option.label}
            onChange={(e, selectedValue) => {
              setFieldValue(`form_input.${field.name}`, selectedValue.value);
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  placeholder={`Select ${field.label}`}
                  error={errors?.form_input && errors?.form_input[field.name]}
                  helperText={
                    errors?.form_input && errors?.form_input[field.name]
                      ? errors?.form_input[field.name]
                      : ""
                  }
                  onChange={(event) => {
                    // setSearchProductKey(event.target.value);
                  }}
                  variant="outlined"
                />
              );
            }}
          />
        </>
      );
  };

  const renderInput = (field) => {
    switch (field.type) {
      case "autocomplete":
        return (
          <>
            <InputLabel className={classes.inputLabel}>
              {field.label}
            </InputLabel>
            <Autocomplete
              freeSolo
              classes={{ inputRoot: classes.inputRootAutoComplete }}
              name={`form_input.${field.name}`}
              id={`form_input.${field.name}`}
              options={field?.list}
              getOptionLabel={(option) => option}
              value={field?.list.find(
                (item) => item == values?.form_input[field?.name]
              )}
              onChange={(e, selectedValue) => {
                // console.log(
                //   "SELECTED_ID:",
                //   field.name,
                //   selectedValue,
                //   `form_input.${field?.name}`
                // );
                setFieldValue(`form_input.${field?.name}`, selectedValue);
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    placeholder={`Select ${field.label}`}
                    error={errors?.form_input && errors?.form_input[field.name]}
                    helperText={
                      errors?.form_input && errors?.form_input[field.name]
                        ? errors?.form_input[field.name]
                        : ""
                    }
                    onChange={(event) => {
                      setFieldValue(
                        `form_input.${field.name}`,
                        event.target.value
                      );
                    }}
                    variant="outlined"
                  />
                );
              }}
            />
          </>
        );
      case "text":
        return (
          <>
            <InputLabel className={classes.inputLabel}>
              {field.label}
            </InputLabel>
            <TextField
              id={field.name}
              name={`form_input.${field.name}`}
              error={errors?.form_input && errors?.form_input[field.name]}
              helperText={
                errors?.form_input && errors?.form_input[field.name]
                  ? errors?.form_input[field.name]
                  : ""
              }
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.form_input[field.name]}
              inputProps={{
                style: { padding: "12px", backgroundColor: "white" },
              }}
              FormHelperTextProps={{ style: { fontSize: "10px" } }}
              placeholder={field.placeholder}
              variant="outlined"
            />
          </>
        );
      case "number":
        return (
          <>
            <InputLabel className={classes.inputLabel}>
              {field.label}
            </InputLabel>
            <TextField
              id={field.name}
              name={`form_input.${field.name}`}
              error={errors?.form_input && errors?.form_input[field.name]}
              helperText={
                errors?.form_input && errors?.form_input[field.name]
                  ? errors?.form_input[field.name]
                  : ""
              }
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.form_input[field.name]}
              inputProps={{
                style: { padding: "12px", backgroundColor: "white" },
              }}
              type="number"
              FormHelperTextProps={{ style: { fontSize: "10px" } }}
              placeholder={field.placeholder}
              variant="outlined"
            />
          </>
        );
      case "select":
        return (
          <>
            <InputLabel className={classes.inputLabel}>
              {field.label}
            </InputLabel>
            {renderSelect(field)}
          </>
        );
      case "date":
        return (
          <>
            <InputLabel className={classes.inputLabel}>
              {field.label}
            </InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                placeholder="-select date-"
                inputVariant="outlined"
                fullWidth
                disablePast={field.disablePast || false}
                disableFuture={field.disableFuture || false}
                id={`date-picker-${field.name}`}
                format="D MMM YYYY"
                value={
                  values?.form_input[field.name]
                    ? moment
                        .unix(values?.form_input[field.name])
                        .format("D MMM Y")
                    : moment().format("D MMM Y")
                }
                labelFunc={() =>
                  values?.form_input[field.name]
                    ? moment
                        .unix(values?.form_input[field.name])
                        .format("D MMM Y")
                    : "- Select date -"
                }
                inputProps={{
                  style: { padding: "10px", fontSize: "14px" },
                }}
                TextFieldComponent={(props) => {
                  return (
                    <TextField
                      {...props}
                      // value={moment(values.purchase_date).format("YYYY-MM-DD")}
                      InputProps={{
                        ...props.InputProps,
                        // className: classes.inputTextField,
                      }}
                    />
                  );
                }}
                onChange={(date) => {
                  console.log("DOB DATE SELECTED: ", date.unix());
                  setFieldValue(`form_input.${field.name}`, date.unix());
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                  id: `datePicker1`,
                }}
              />
            </MuiPickersUtilsProvider>
          </>
        );
      case "password":
        return (
          <>
            <InputLabel className={classes.inputLabel}>
              {field.label}
            </InputLabel>
            <TextField
              id={field.name}
              name={`form_input.${field.name}`}
              error={errors?.form_input && errors?.form_input[field.name]}
              helperText={
                errors?.form_input && errors?.form_input[field.name]
                  ? errors?.form_input[field.name]
                  : ""
              }
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.form_input[field.name]}
              inputProps={{
                style: { padding: "12px", backgroundColor: "white" },
              }}
              FormHelperTextProps={{ style: { fontSize: "10px" } }}
              placeholder={field.placeholder}
              variant="outlined"
            />
          </>
        );
      default:
        return "Invalid Type";
    }
  };

  if (isLogicInComponent) {
    return formData.map((field, index) => {
      return (
        <React.Fragment key={index}>
          {children({ jsx: renderInput(field) })}
        </React.Fragment>
      );
    });
  }

  return (
    <Grid container spacing={2} style={{ padding: "15px", width: "100%" }}>
      {formData.map((field, index) => {
        return (
          <Grid item key={index} xs={12}>
            {renderInput(field)}
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={submitForm}>
          Submit
        </Button>
      </Grid>
    </Grid>
  );
}

export default DynamicForm;

DynamicForm.defaultProps = {
  isLogicInComponent: false,
};
