import axios from "axios";
import { BASE_URL } from "./axiosConfig";
import { USER_ID, USER_TOKEN } from "./constants";
import { decryptor } from "../utils/encryption-decryption";

const myInstance = axios.create();

function getUserId() {
  return window.localStorage.getItem(USER_ID);
}

function getUserToken() {
  return window.localStorage.getItem(USER_TOKEN);
}

export function httpClient(
  method,
  endpoint,
  { body, cancelToken = "", ...customConfig } = {}
) {
  const baseUrl = BASE_URL;

  const headers = {
    "content-type": "application/json",
    env: process.env.REACT_APP_ENVIRONMENT,
  };

  if (getUserId()) {
    headers.Authorization = `Bearer ${getUserToken()}`;
  }

  const config = {
    method,
    baseURL: baseUrl,
    url: endpoint,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
    responseType: "json",
    timeout: customConfig.timeout ? customConfig.timeout : 0,
    cancelToken,
  };

  config.data = body;

  return myInstance(config)
    .then((response) => {
      const { data } = response;
      let finalResponse = {};

      const { is_encrypted, result } = data;

      if (is_encrypted) {
        let ENC_KEY_128 = process.env.REACT_APP_DECRYPTION_KEY;
        finalResponse = JSON.parse(decryptor(ENC_KEY_128, result));
      } else finalResponse = data;

      return Promise.resolve(finalResponse);
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });
}
