import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import _ from "lodash";
import { makeStyles, CircularProgress } from "@material-ui/core";

function ConfirmationModal({
  title,
  maxWidth,
  status,
  children,
  closeBtnText,
  saveBtnText,
  saveHandler,
  closeHandler,
  height,
  width,
  headerComponent,
  loading,
}) {
  const useStyles = makeStyles((theme) => ({
    modalPaper: {
      width: _.isUndefined(width) ? "100%" : width,
      height: _.isUndefined(height) ? "440px" : height,
      borderRadius: "8px",
    },
  }));

  const classes = useStyles();

  return (
    <div>
      <Dialog
        maxWidth={_.isUndefined(maxWidth) ? "sm" : maxWidth}
        classes={{ paper: classes.modalPaper }}
        style={{ width: "100%", textAlign: "center" }}
        open={status}
        onClose={closeHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          style={{ borderBottom: "1px solid #eaeaea", padding: "9px 16px" }}
        >
          {_.isUndefined(title) ? headerComponent() : title}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions style={{ justifyContent: "center", padding: "16px" }}>
          {!_.isUndefined(closeBtnText) && (
            <Button variant="outlined" onClick={closeHandler} color="primary">
              {_.isUndefined(closeBtnText) ? "Close" : closeBtnText}
            </Button>
          )}
          {!_.isUndefined(saveBtnText) && (
            <Button
              variant="contained"
              onClick={saveHandler}
              color="primary"
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={23} />
              ) : (
                <>{_.isUndefined(saveBtnText) ? "Save" : saveBtnText}</>
              )}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ConfirmationModal;
