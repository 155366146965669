import {
  Box,
  Button,
  DialogContentText,
  Grid,
  IconButton,
  useTheme,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import _ from "lodash";
import moment from "moment";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useAuth } from "../../providers/AuthProvider";
import { currency } from "../../utils/utility";
import Modal from "../../MuiComponent/Modal";
import AntSwitch from "../../components/AntSwitch";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import { updateConfigurations } from "../../store/actions";
var converter = require("number-to-words");

const Composition = forwardRef(({ data, onBack }, ref) => {
  const theme = useTheme();
  const { userData } = useAuth();
  const [isDiscountProvided, setIsDiscountProvider] = useState(false);
  const dispatch = useDispatch();
  const [modalStatus, setModalStatus] = useState(false);

  function printdiv(elem) {
    var header_str =
      "<html><head><title>" + document.title + "</title></head><body>";
    var footer_str = "</body></html>";
    var new_str = document.getElementById(elem).innerHTML;
    var old_str = document.body.innerHTML;
    document.body.innerHTML = header_str + new_str + footer_str;
    window.print();
    document.body.innerHTML = old_str;
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useImperativeHandle(
    ref,
    () => {
      return {
        handlePrint,
      };
    },
    []
  );

  const {
    userData: { businessDetails },
  } = useSelector((state) => state.general);

  const { values, setFieldValue, submitForm } = useFormik({
    initialValues: {
      ...businessDetails?.business_configurations?.bill_configurations,
    },
    onSubmit: () => {
      dispatch(
        updateConfigurations({
          key: "bill_configurations",
          value: values,
        })
      );
    },
  });

  useEffect(() => {
    if (values.customer_copy) {
      const element = document.querySelector("#copy_table");
      const elementToCopy = document.querySelector("#customer_copy");

      elementToCopy.innerHTML = element.innerHTML;
    }

    data.items.map((item) => {
      if (item.discount > 0) {
        setIsDiscountProvider(true);
      }
    });
  }, [data, values, isDiscountProvided]);

  const ModalHeader = () => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ fontSize: "14px" }}
        >
          <Box textAlign="left" style={{ fontWeight: 600 }} padding={1}>
            Bill Settings
          </Box>
          <IconButton
            style={{ position: "absolute", right: "20px" }}
            onClick={() => {
              setModalStatus(!modalStatus);
            }}
          >
            <CloseIcon size="small" />
          </IconButton>
        </Box>
      </>
    );
  };

  return (
    <>
      <Modal
        role="add-sales-dialog"
        headerComponent={ModalHeader}
        height="320px"
        // buttonAlign="center"
        width="100%"
        closeBtnText="Cancel"
        saveBtnText="Proceed"
        // loading={actionApiStatus.isPending}
        saveHandler={() => {
          setModalStatus(!modalStatus);
          submitForm();
        }}
        closeHandler={() => {
          setModalStatus(!modalStatus);
        }}
        status={modalStatus}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box alignItems={"center"} display={"flex"}>
                <Box flex={1} component={"span"}>
                  Footer
                </Box>
                <AntSwitch
                  checked={values.footer_in_detailed_composition_bill}
                  onChange={(e) => {
                    setFieldValue(e.target.name, e.target.checked);
                  }}
                  name="footer_in_detailed_composition_bill"
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box alignItems={"center"} display={"flex"}>
                <Box flex={1} component={"span"}>
                  Customer Copy
                </Box>
                <AntSwitch
                  checked={values.customer_copy}
                  onChange={(e) => {
                    setFieldValue(e.target.name, e.target.checked);
                  }}
                  name="customer_copy"
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>
      <Grid container style={{ padding: theme.spacing(3) }}>
        <Grid item xs={12}>
          <Box display={"flex"}>
            <Box flex={1}>
              <Button
                onClick={() => {
                  onBack();
                }}
              >
                <ArrowBackIosIcon /> Back
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setModalStatus(!modalStatus);
                }}
              >
                Settings
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box style={{ backgroundColor: "white" }}>
            <Box
              ref={componentRef}
              id="printable_div_id"
              style={{ margin: "10px 10px", marginLeft: "20px" }}
            >
              <meta
                httpEquiv="Content-Type"
                content="text/html; charset=utf-8"
              />
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    "\n        .page-break {\n            page-break-after: always;\n        }\n        table{\n            border-collapse: collapse;\n            width: 100%;\n        }\n",
                }}
              />
              <div style={{ textAlign: "center" }}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontWeight: "bold", fontSize: 22, flex: 1 }}>
                    BILL OF SUPPLY
                  </div>
                  <div>(ORIGINAL FOR RECIPIENT)</div>
                </div>
              </div>

              <div id="copy_table">
                <table border={1}>
                  <tbody>
                    <tr>
                      <td colSpan={2}>
                        {_.upperCase(businessDetails.name)}
                        <div>{businessDetails.address}</div>
                        {/* 156, B-Block Sec-14 Udaipur (Raj.) */}
                        <div>
                          {businessDetails.city_name +
                            ", " +
                            businessDetails.state_name +
                            " (" +
                            businessDetails.pincode +
                            ")"}
                        </div>
                        <div>Mo: {businessDetails.contact_number}</div>
                        <div>Email: {businessDetails.email}</div>
                      </td>
                      <td rowSpan={2} colSpan={4}>
                        <table width="100%" height="200px">
                          <tbody>
                            <tr style={{ borderBottom: "1px solid" }}>
                              <td
                                style={{ borderRight: "1px solid" }}
                                width="50%"
                              >
                                Invoice Number
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {data.invoice_number}
                              </td>
                            </tr>
                            <tr style={{ borderBottom: "1px solid" }}>
                              <td
                                style={{ borderRight: "1px solid" }}
                                width="50%"
                              >
                                Date
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {moment(data.created_at).format("DD MMM YYYY")}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ borderRight: "1px solid" }}
                                width="50%"
                              >
                                Mode
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {_.capitalize(data.payment_mode)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <div>Buyer (Bill to)</div>
                        <Box fontWeight="600">{data?.name}</Box>
                        <Box>Mob: {data.mobile}</Box>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={5}>
                        <table>
                          <tbody>
                            <tr style={{ borderBottom: "1px solid" }}>
                              <td width="70px">S.No</td>
                              <td>Description of Goods</td>
                              <td>Quantity</td>
                              <td>Rate</td>
                              <td>{isDiscountProvided && "Discount(%)"}</td>
                              <td>{/* Tax */}</td>
                              <td>Amount (₹)</td>
                            </tr>
                            {data.items.map((item, index) => {
                              return (
                                <tr>
                                  <td width="70px">{index + 1}</td>
                                  <td>
                                    {" "}
                                    {item.name + " "}
                                    {Object.keys(item.options).length > 0 &&
                                      `[${Object.keys(item.options)
                                        .map((key) => {
                                          return `${key}: ${item.options[key].value}`;
                                        })
                                        .join(", ")}]`}
                                    {/* {(() => {
                                  if (Array.isArray(item.options) == false) {
                                    let string = [];

                                    Object.keys(item.options).map((key) => {
                                      string.push(
                                        `${key}: ${item.options[key][0].value}, `
                                      );
                                    });

                                    return `[ ${string.join(", ")}]`;
                                  }
                                })()} */}
                                  </td>
                                  <td>{item.quantity}</td>
                                  <td>
                                    {currency({
                                      value: item.product_price,
                                      currency: userData.currency,
                                    })}
                                  </td>
                                  <td>{isDiscountProvided && `${item.discount}%`}</td>
                                  <td></td>
                                  <td>
                                    {currency({
                                      value: item.total_price,
                                      currency: userData.currency,
                                    })}
                                  </td>
                                </tr>
                              );
                            })}
                            {data.discount > 0 && (
                              <>
                                <tr style={{ borderTop: "1px solid" }}>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      fontWeight: "bold",
                                      paddingRight: 30,
                                    }}
                                    colSpan={2}
                                  >
                                    Total
                                  </td>
                                  <td>{/* 03 */}</td>
                                  <td colSpan={2}></td>
                                  <td>{/* 03 */}</td>
                                  <td style={{ fontWeight: "bold" }}>
                                    {currency({
                                      value: data.total,
                                      currency: userData.currency,
                                    })}
                                  </td>
                                </tr>
                                <tr style={{ borderTop: "1px solid" }}>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      fontWeight: "bold",
                                      paddingRight: 30,
                                    }}
                                    colSpan={2}
                                  >
                                    Discount ({data.discount}%)
                                  </td>
                                  <td>{/* 03 */}</td>
                                  <td colSpan={2}></td>
                                  <td>{/* 03 */}</td>
                                  <td style={{ fontWeight: "bold" }}>
                                    {currency({
                                      value: (data.total * data.discount) / 100,
                                      currency: userData.currency,
                                    })}
                                  </td>
                                </tr>
                              </>
                            )}

                            <tr style={{ borderTop: "1px solid" }}>
                              <td
                                style={{
                                  textAlign: "right",
                                  fontWeight: "bold",
                                  paddingRight: 30,
                                }}
                                colSpan={2}
                              >
                                Grand Total
                              </td>
                              <td>{/* 03 */}</td>
                              <td colSpan={2}></td>
                              <td>{/* 03 */}</td>
                              <td style={{ fontWeight: "bold" }}>
                                {currency({
                                  value: data.total_payable,
                                  currency: userData.currency,
                                })}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr style={{ borderTop: "1px solid" }}>
                      <td style={{ paddingRight: 30 }}>
                        Amount Chargeable (in words)
                      </td>
                    </tr>
                    <tr style={{ borderTop: "1px solid" }}>
                      <td style={{ paddingRight: 30, fontWeight: "bold" }}>
                        INR{" "}
                        {data.total_payable &&
                          _.startCase(
                            converter.toWords(data.total_payable)
                          )}{" "}
                        Only
                      </td>
                    </tr>
                    {values.footer_in_detailed_composition_bill && (
                      <>
                        <tr style={{ borderTop: "1px solid" }}>
                          <td
                            style={{
                              paddingRight: 30,
                              borderRight: "1px solid",
                              width: "50%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                height: 100,
                                flexDirection: "column",
                              }}
                            >
                              <div style={{ flex: 1 }}>
                                Company PAN: {businessDetails.pancard}
                              </div>
                              <div>
                                <div>Declaration:</div>
                                <div style={{ fontStyle: "italic" }}>
                                  We declare that this invoice shows the actual
                                  price of the goods described and that all
                                  particulars are true and correct.
                                </div>
                              </div>
                            </div>
                          </td>
                          <td style={{ paddingRight: 30 }} colSpan={2}>
                            <div>Company's Bank Details</div>
                            <div>
                              A/C Holder Name:{" "}
                              {
                                businessDetails?.account_details
                                  ?.account_holder_name
                              }
                            </div>
                            <div>
                              Bank Name:{" "}
                              {businessDetails?.account_details?.bank_name}
                            </div>
                            <div>
                              A/C No.:{" "}
                              {businessDetails?.account_details?.account_number}
                            </div>
                            <div>
                              IFS Code:{" "}
                              {businessDetails?.account_details?.ifsc_code}
                            </div>
                          </td>
                        </tr>
                        <tr style={{ borderTop: "1px solid", height: 100 }}>
                          <td
                            style={{
                              paddingRight: 30,
                              borderRight: "1px solid",
                              width: "50%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                height: 100,
                                flexDirection: "column",
                              }}
                            >
                              <div>Customer's Seal &amp; Signature</div>
                            </div>
                          </td>
                          <td
                            style={{ paddingRight: 30, textAlign: "right" }}
                            colSpan={2}
                          >
                            <div
                              style={{
                                display: "flex",
                                height: 100,
                                flexDirection: "column",
                              }}
                            >
                              <div style={{ flex: 1 }}>
                                For Jain Electricals
                              </div>
                              <div>Authorised Signatory</div>
                            </div>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              {values.customer_copy && (
                <>
                  <div style={{ textAlign: "center", marginTop: "100px" }}>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{ fontWeight: "bold", fontSize: 22, flex: 1 }}
                      >
                        BILL OF SUPPLY
                      </div>
                      <div>(Customer Copy)</div>
                    </div>
                  </div>
                  <div id="customer_copy"></div>
                </>
              )}
            </Box>
          </Box>
          <Box textAlign={"end"} marginRight={"10px"}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                handlePrint();
                // printdiv("printable_div_id");
                // window.print();
              }}
            >
              Print
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
});


Composition.defaultProps = {
  data: {
    items: [],
  },
};

export default Composition;
