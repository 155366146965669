import {
  Typography
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Header from "../layouts/Header";
import AddProduct from "./product/AddProduct";
import ProductDatatable from "./product/ProductDatatable";

function ProductWithEditMode() {
  const [product_id, setProductId] = useState("");
  const history = useHistory();

  useEffect(() => {
    let productId = history.location.pathname.split("/")[2];
    setProductId(productId);
  }, [history.location.pathname]);

  return <AddProduct id={product_id} editMode={true} />;
}
function ProductManagement() {
  const datatableRef = useRef();

  return (
    <>
      <Header>
        <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
          Product Management
        </Typography>
      </Header>

      {/* <Box className={clsx({ [classes.hidden]: pageName != "editRecord" })}> */}
      <Route exact path="/products/:id/edit">
        <ProductWithEditMode />
      </Route>
      {/* </Box> */}
      {/* <Box className={clsx({ [classes.hidden]: pageName != "addRecord" })}> */}
      <Route exact path="/products/add">
        <AddProduct />
      </Route>
      {/* </Box> */}
      {/* <Box className={clsx({ [classes.hidden]: pageName != "datatable" })}> */}
      <Route exact path="/products">
        <ProductDatatable ref={datatableRef} />
      </Route>
      {/* </Box> */}
    </>
  );
}

export default ProductManagement;
