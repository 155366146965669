export const SET_USER = "SET_USER";
export const IS_COMPONENT_BUSY = "IS_COMPONENT_BUSY";

export const SET_PARENT_CATEGORIES = "SET_PARENT_CATEGORIES";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const GENERAL_API_CALL = "GENERAL_API_CALL";
export const FETCH_STATE_DATA = "FETCH_STATE_DATA";
export const SET_REDUCER_DATA = "SET_REDUCER_DATA";
export const GENERAL_API_CALL_DATA = "GENERAL_API_CALL_DATA";
export const FETCH_CITIES_DATA = "FETCH_CITIES_DATA";
export const FETCH_COUNTRY_DATA = "FETCH_COUNTRY_DATA";
export const UPDATE_BUSINESS_CONFIGURATION = "UPDATE_BUSINESS_CONFIGURATION";

export const TYPES = {
  FETCH_PRODUCTS_PROGRESSIVELY:"products/FETCH_PRODUCTS_PROGRESSIVELY",
  UPDATE_WEBSITE_META: "meta/UPDATE_WEBSITE_META",
  DELETE_OFFERED_PRODUCT: "offer/DELETE_OFFERED_PRODUCT",
  ADD_PRODUCT_OFFER: "offers/ADD_PRODUCT_OFFER",
  UPDATE_PRODUCT_OFFER: "offers/UPDATE_PRODUCT_OFFER",
  FETCH_PRODUCT_OFFERS_DATA: "offers/FETCH_PRODUCT_OFFERS_DATA",
  SET_PRODUCT_OFFERS_DATA: "offers/SET_PRODUCT_OFFERS_DATA",
  FETCH_ORDERS_DATA: "orders/FETCH_ORDERS_DATA",
  SET_ORDERS_DATA: "orders/SET_ORDERS_DATA",
  FETCH_SUPPLIERS_DATA: "users/FETCH_SUPPLIERS_DATA",
  SET_SUPPLIERS_DATA: "users/SET_SUPPLIERS_DATA",
  FETCH_INVENTORY_DATA: "inventory/FETCH_INVENTORY_DATA",
  SET_INVENTORY_DATA: "inventory/SET_INVENTORY_DATA",
  FETCH_POS_DATA: "pos/FETCH_POS_DATA",
  FETCH_CATEGORY_LIST: "category/FETCH_CATEGORY_LIST",
  CREATE_SLOTS: "slot/CREATE_SLOTS",
  UPDATE_SLOTS: "slots/UPDATE_SLOTS",
  FETCH_PRODUCTS: "products/FETCH_PRODUCTS",
  FETCH_PRODUCTS_LIST: "products/FETCH_PRODUCTS_LIST",
  FETCH_PURCHASE_DATA: "purchase/FETCH_PURCHASE_DATA",
  SET_PRODUCTS_LIST: "products/SET_PRODUCTS_LIST",
  FETCH_SLOTS: "/order/FETCH_SLOTS",
  FETCH_COLLECTED_ORDERS: "/orders/FETCH_COLLECTED_ORDERS",
  FETCH_ALL_USERS_DATA: "/users/FETCH_ALL_USERS_DATA",
  SET_COLLECTED_ORDERS: "order/SET_COLLECTED_ORDERS",
  SET_DATATABLE_USER_LIST: "users/SET_DATATABLE_USER_LIST",
  SET_SLOTS: "/order/SET_SLOTS",
  GET_ONLINE_ORDER_BY_ID: "order/GET_ONLINE_ORDER_BY_ID",
  SET_LOADER: "loader/SET_LOADER",
  SET_POS_DATA: "pos/SET_POS_DATA",
  DELETE_POS_BILL: "order/DELETE_POS_BILL",
  UPDATE_POS_ORDER_PAYMENT_STATUS: "order/UPDATE_POS_ORDER_PAYMENT_STATUS",
  SET_PURCHASE_DATA: "purchase/SET_PURCHASE_DATA",
  GET_ORDER_BY_ID: "order/GET_ORDER_BY_ID",
  SET_ORDER_BY_ID: "order/SET_ORDER_BY_ID",
  ADD_POS_ORDER: "order/ADD_POS_ORDER",
  SET_PRODUCTS_DATA: "products/SET_PRODUCTS_DATA",
  UPDATE_CONFIGURATIONS: "user/business/UPDATE_CONFIGURATIONS",
  SET_CONFIGURATIONS: "user/business/SET_CONFIGURATIONS",
  SYNC_CACHE_DATA: "partner/SYNC_CACHE_DATA",
  FETCH_USER_DATA: "user/FETCH_USER_DATA",
  GET_FORM_STRUCTURE: "user/GET_FORM_STRUCTURE",
  SET_FORM_STRUCTURE: "user/SET_FORM_STRUCTURE",
  SET_USER_LIST: "user/SET_USER_LIST",
  ADD_CUSTOMER: "partner/ADD_CUSTOMER",
  UPDATE_CUSTOMER: "partner/UPDATE_CUSTOMER",
  FETCH_USERS_LIST: "user/FETCH_USERS_LIST",
  SET_FORM_VALIDATIONS: "form/SET_FORM_VALIDATIONS",
  FETCH_SERVICE_BILLS: "form/FETCH_SERVICE_BILLS",
  GET_CUSTOMER_BY_ID: "user/GET_CUSTOMER_BY_ID",
  SET_SERVICE_BILLS: "datatable/SET_SERVICE_BILLS",
  SET_CUSTOMER_BY_ID: "datatable/SET_CUSTOMER_BY_ID",
  UPDATE_SERVICE_BILL_TRANSACTION: "bill/UPDATE_SERVICE_BILL_TRANSACTION",
};

export const PERMISSIONS = {
  USER_MANAGEMENT: "User Management",
  TASK_MANAGEMENT: "Task Management",
  SERVICE_BILLS: "Service Bills",
  SERVICE_MANAGEMENT: "Service Management",
  ROLE_ACCESS: "Role Management",
  CATEGORY_MANAGEMENT: "Category Management",
  PRODUCT_MANAGEMENT: "Product Management",
  POINT_OF_SALE: "Point of Sale",
  REVIEW_RATING: "Reviews & Ratings",
  NEWSLETTER_INQUIREY: "Newsletters & Inquiries",
  PURCHASE_MANAGEMENT: "Purchase Management",
  BANNER_MANAGEMENT: "Banner Management",
  SLIDER_MANAGEMENT: "Slider Management",
  ORDER_MANAGEMENT: "Order Management",
  TRANSACTION_MANAGEMENT: "Transaction Management",
  CMS_MANAGEMENT: "CMS Management",
  COUPON_MANAGEMENT: "Coupon Management",
  SETTINGS: "Settings",
  STAFF_MANAGEMENT: "Staff Management",
  DASHBOARD: "Dashboard",
};

export const API_KEYS = {
  FETCH_PAGINATED_PRODUCTS: "products/fetch_paginated_products",
  FETCH_LOGGED_IN_USER: "user/FETCH_LOGGED_IN_USER",
};
