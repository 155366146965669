import { createReducer } from "@reduxjs/toolkit";
import {
  setInventoryData,
  setOrdersData,
  setPOSData,
  setProductOffersData,
  setProductsList,
  setPurchaseData,
  setSuppliersData,
} from "../actions";

const { SET_CATEGORIES, SET_PARENT_CATEGORIES } = require("../constants");

let initialState = {
  categories: {
    data: [],
    links: {},
    meta: {},
  },
  products: {
    data: [],
    links: {},
    meta: {},
  },
  purchases: {
    data: [],
    links: {},
    meta: {},
  },
  pos: {
    data: [],
    links: {},
    meta: {},
  },
  inventory: {
    data: [],
    links: {},
    meta: {},
  },
  suppliers: {
    data: [],
    links: {},
    meta: {},
  },
  orders: {
    data: [],
    links: {},
    meta: {},
  },
  product_offers: {
    data: [],
    links: {},
    meta: {},
  },
  parent_categories: [],
};

const PortalDataReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(SET_CATEGORIES, (state, action) => {
      state.categories = action.payload;
    })
    .addCase(SET_PARENT_CATEGORIES, (state, action) => {
      state.parent_categories = action.payload;
    })
    .addCase(setProductsList, (state, action) => {
      state.products = action.payload;
    })
    .addCase(setPurchaseData, (state, action) => {
      state.purchases = action.payload;
    })
    .addCase(setPOSData, (state, action) => {
      state.pos = action.payload;
    })
    .addCase(setInventoryData, (state, action) => {
      state.inventory = action.payload;
    })
    .addCase(setSuppliersData, (state, action) => {
      state.suppliers = action.payload;
    })
    .addCase(setProductOffersData, (state, action) => {
      state.product_offers = action.payload;
    })
    .addCase(setOrdersData, (state, action) => {
      state.orders = action.payload;
    })
    .addDefaultCase((state, action) => state);
});

export default PortalDataReducer;
