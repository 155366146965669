import { createBuffer, decryptor, encryptor } from "./encryption-decryption";

let openRequest = null;

if ("indexedDB" in window) {
  openRequest = indexedDB.open("partner", 1);

  openRequest.onsuccess = () => {
    console.log("IndexDB: Open Request Created");
  };

  openRequest.onupgradeneeded = () => {
    console.log("IndexDB: On upgrade needed");
    const db = openRequest.result;

    if (!db.objectStoreNames.contains("products")) {
      let store = db.createObjectStore("products", { keyPath: "id" });
      store.createIndex("name", "name", { unique: false });
    }
  };

  openRequest.onerror = (er) => {
    console.log("IndexDB: Something went wrong while accessing database");
  };
}

const isBrowserSupport = () => {
  return "indexedDB" in window;
};

const indexDB = {
  removeObjectStore: function (objectStore) {
    if (isBrowserSupport()) {
      const db = openRequest.result;
      let tx = db.transaction(objectStore, "readwrite");
      let store = tx.objectStore(objectStore);
      store.clear();
    }
  },
  removeItem: function (objectStore, id) {
    if (isBrowserSupport()) {
      console.log(objectStore, id);
      const db = openRequest.result;
      let tx = db.transaction(objectStore, "readwrite");
      let store = tx.objectStore(objectStore);
      let result = store.delete(id);

      return new Promise((resolve, reject) => {
        result.onsuccess = (result) => {
          resolve(result);
        };
      });
    }
  },
  readData: function (objectStore) {
    if (isBrowserSupport()) {
      let ENC_KEY_128 = process.env.REACT_APP_DECRYPTION_KEY;
      const db = openRequest.result;
      let tx = db.transaction(objectStore, "readonly");
      let table = tx.objectStore(objectStore);
      let records = table.getAll();

      return new Promise((resolve, reject) => {
        records.onsuccess = (e) => {
          let data = e.target.result;
          let result = data.map((item) => {
            return JSON.parse(decryptor(ENC_KEY_128, item.value));
          });
          resolve(result);
        };
      });
    }
  },
  writeData: function (objectStore, data) {
    if (isBrowserSupport()) {
      const db = openRequest.result;
      var tx = db.transaction(objectStore, "readwrite");
      var store = tx.objectStore(objectStore);

      let result = null;

      let ENC_KEY_128 = process.env.REACT_APP_DECRYPTION_KEY;

      if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
          let bufferedData = createBuffer(data[i]);

          let finalResponse = encryptor(ENC_KEY_128, bufferedData);
          result = store.put({
            id: data[i].id,
            name: data[i].name,
            value: finalResponse,
          });
        }
      } else {
        let bufferedData = createBuffer(data);

        let finalResponse = encryptor(ENC_KEY_128, bufferedData);
        result = store.put({
          id: data.id,
          name: data.name,
          value: finalResponse,
        });
      }

      return new Promise((resolve, reject) => {
        resolve({ success: "ok", data: result });
      });
    } else throw Error("Browser Not Support IndexDB Service");
  },
};

export { indexDB };
