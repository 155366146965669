import { createReducer } from "@reduxjs/toolkit";
import { setCustomerById, setServiceBills } from "../actions";

let initialState = {
  customer_data: {},
  service_bills: {
    data: [],
    links: {},
    meta: {},
  },
};

const BillingReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setCustomerById, (state, action) => {
      state.customer_data = action.payload;
    })
    .addCase(setServiceBills, (state, action) => {
      state.service_bills = action.payload;
    })
    .addDefaultCase((state) => state);
});

export default BillingReducer;
