import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Select,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useTheme } from "@material-ui/styles";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import moment from "moment";
import React, { lazy, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useGeneralStyle from "../../assets/css/general";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import Header from "../../layouts/Header";
import Modal from "../../MuiComponent/Modal";
import { useAuth } from "../../providers/AuthProvider";
import {
  getCustomerById,
  getServiceBills,
  updateServiceBillTransaction,
} from "../../store/actions";
import {
  selectBillableCustomer,
  selectServiceBills,
} from "../../store/selectors";
import ServiceDetailPreview from "./ServiceDetailPreview";
import _ from "lodash";
import ConfirmationModal from "../../MuiComponent/ConfirmationModal";
import { padding } from "aes-js";
import { currency } from "../../utils/utility";
const Datatable = lazy(() => import("../../MuiComponent/Datatable"));

const tableHeader = [
  { id: "name", align: "left", label: "DATE & TIME", minWidth: 170 },
  {
    id: "size",
    label: "SERVICE NAME",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "TOTAL",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "STATUS",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "density",
    label: "VIEW",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "density",
    label: "ACTION",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

function DetailedPreview() {
  let total_amount;
  const getAllUserApiStatus = useCallbackStatus();
  const { customer_id } = useParams();
  const theme = useTheme();
  const [search, setSearchKey] = useState("");
  const [modal, setModal] = React.useState(false);
  const [rowData, setRowData] = useState({
    services: [],
  });
  const [paymentMode, setPaymentMode] = useState("paid");
  const [confirmation, setConfirmation] = React.useState(false);
  const globalClasses = useGeneralStyle();
  const auth = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const customerData = useSelector(selectBillableCustomer);
  const {
    data: serviceBills,
    meta: { total: serviceBillTotal },
  } = useSelector(selectServiceBills);

  useEffect(() => {
    dispatch(
      getCustomerById({
        user_type: "service_customers",
        id: customer_id,
        paymentMode,
      })
    );
  }, [paymentMode]);

  const fetchBills = async (pageSize = 10, pageNumber = 1) => {
    dispatch(
      getServiceBills({
        pageSize,
        pageNumber,
        search,
        paymentMode,
      })
    );
  };

  const ModalHeader = () => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ fontSize: "14px" }}
        >
          <Box textAlign="left" style={{ fontWeight: 600 }} padding={1}>
            Bill Details
          </Box>
          <IconButton
            style={{ position: "absolute", right: "20px" }}
            onClick={() => {
              setModal(!modal);
            }}
          >
            <CloseIcon size="small" />
          </IconButton>
        </Box>
      </>
    );
  };

  return (
    <>
      <Modal
        height="340px"
        width="100%"
        headerComponent={ModalHeader}
        saveBtnText="OKAY"
        saveHandler={() => {
          setModal(!modal);
        }}
        status={modal}
      >
        <ServiceDetailPreview data={rowData} />
      </Modal>

      <ConfirmationModal
        height={170}
        width="auto"
        title="Are you sure"
        status={confirmation}
        closeBtnText="cancel"
        saveBtnText="Proceed"
        closeHandler={() => setConfirmation(!confirmation)}
        saveHandler={() => {
          dispatch(
            updateServiceBillTransaction({
              paymentMode,
              search: customerData.mobile_number,
            })
          );
          setConfirmation(!confirmation);
        }}
      >
        Are you sure want to mark all unpaid transactions as paid?
      </ConfirmationModal>

      <Header>
        <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
          Service Bills
        </Typography>
      </Header>
      <Grid container spacing={1} style={{ padding: theme.spacing(3) }}>
        <Grid item xs={12}>
          <Box style={{ paddingBottom: theme.spacing(3) }}>
            <Button
              onClick={() => {
                history.goBack();
              }}
            >
              <ArrowBackIosIcon /> Back
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Paper
            elevation={0}
            style={{
              border: "1px solid #e0e0e0",
              padding: 10,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4}>
                <Box className={globalClasses.inputLabel}>Name</Box>{" "}
                {customerData.name}
              </Grid>
              <Grid item xs={6} sm={4}>
                <Box className={globalClasses.inputLabel}>Last Paid</Box>
                {customerData.last_paid
                  ? moment(customerData.last_paid).format("DD MMM YYYY")
                  : "N/A"}
              </Grid>
              <Grid item xs={6} sm={4}>
                <Box className={globalClasses.inputLabel}>Billing Type</Box>
                <Select
                  className={globalClasses.selectInput}
                  style={{ paddingRight: "20px", width: "100px" }}
                  labelId="demo-simple-select-outlined-label"
                  value={paymentMode}
                  onChange={(e) => setPaymentMode(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="paid">Paid</MenuItem>
                  <MenuItem value="unpaid">Unpaid</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Box className={globalClasses.inputLabel}>Mobile Number</Box>
                {customerData.mobile_number}
              </Grid>

              <Grid item xs={6} sm={4}>
                <Box className={globalClasses.inputLabel}>
                  Total {_.capitalize(paymentMode)} Amount
                </Box>
                {currency({
                  value: serviceBills.reduce((total_amount, data) => {
                    total_amount = total_amount + parseFloat(data.total);
                    return total_amount;
                  }, 0),
                })}
              </Grid>

              <Grid item xs={6} sm={4}>
                {paymentMode == "unpaid" && (
                  <Button
                    onClick={() => setConfirmation(!confirmation)}
                    style={{ fontWeight: "600" }}
                    variant="contained"
                    color="primary"
                  >
                    Collect
                  </Button>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ border: "1px solid #e0e0e0" }}>
            <Datatable
              loading={getAllUserApiStatus.isPending}
              getDataList={fetchBills}
              totalRecords={serviceBillTotal}
              tableHeader={tableHeader}
            >
              {serviceBills.map((row, index) => {
                return (
                  <TableRow key={index} hover>
                    <TableCell>
                      {moment.unix(row.billing_date).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>
                      {row.services.map((service) => service.name).join(", ")}
                    </TableCell>
                    <TableCell>{currency({ value: row.total })}</TableCell>
                    <TableCell>{_.capitalize(row.payment_status)}</TableCell>
                    <TableCell>
                      <a
                        onClick={() => {
                          setRowData(row);
                          setModal(!modal);
                        }}
                        className={globalClasses.link}
                      >
                        View
                      </a>
                    </TableCell>
                    <TableCell>
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                          <React.Fragment>
                            <IconButton {...bindTrigger(popupState)}>
                              <MoreHorizIcon />
                            </IconButton>
                            <Menu {...bindMenu(popupState)}>
                              <MenuItem
                                onClick={() => {
                                  // setselectedData(item);
                                  // setModalDel(true);
                                  popupState.setOpen(false);
                                }}
                              >
                                Delete
                              </MenuItem>
                            </Menu>
                          </React.Fragment>
                        )}
                      </PopupState>
                    </TableCell>
                  </TableRow>
                );
              })}
            </Datatable>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default DetailedPreview;
