import React from "react";
import "./App.css";
import AppProvider from "./layouts/AppProvider";
import RouteList from "./routes/RouteList";
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";

function App() {
  return (
    <ErrorBoundary>
      <AppProvider>
        <RouteList />
      </AppProvider>
    </ErrorBoundary>
  );
}

export default App;
