import _ from "lodash";
import DashboardIcon from "../assets/images/drawer/Dashboard.svg";
import CompanyInfoIcon from "../assets/images/drawer/companyInfo.svg";
import GuideIcon from "../assets/images/drawer/guide.svg";
import MeetingIcon from "../assets/images/drawer/meeting_management.svg";
import rolesIcon from "../assets/images/drawer/rolesIcon.svg";
import SettingIcon from "../assets/images/drawer/setting.svg";
import TemplateIcon from "../assets/images/drawer/template.svg";
import UserIcon from "../assets/images/drawer/user.svg";
import { PERMISSIONS } from "../store/constants";

export const hasAccess = (page) => {
  // console.log(
  //   "Permission HAS ACESS: ",
  //   JSON.parse(localStorage.getItem("permissions"))
  // );
  let activePages = [];
  activePages = JSON.parse(localStorage.getItem("permissions"));

  if (!_.isNull(activePages) && activePages.includes(page)) {
    return true;
  } else return false;
};

const renderPath = () => {
  const activePages = JSON.parse(localStorage.getItem("permissions")) || [];

  if (activePages.includes(PERMISSIONS.SERVICE_MANAGEMENT)) {
    return "/users/service-customers";
  } else if (activePages.includes(PERMISSIONS.STAFF_MANAGEMENT)) {
    return "/users/service-staff";
  } else if (activePages.includes(PERMISSIONS.PURCHASE_MANAGEMENT)) {
    return "/users/suppliers";
  } else {
    return "/users/walk-in";
  }
};

const menu = [
  {
    text: "Dashboard", //Name of List Item
    icon: DashboardIcon, //Icon of List Item
    hasAccess: hasAccess(PERMISSIONS.DASHBOARD),
    rootpath: "dashboard", //RootPath used for selected view
    url: "/", //After Click where you want to redirect
    active: false,
    child: [
      // {
      //   text: "Expert analytics",
      //   icon: null,
      //   hasAccess: true,
      //   rootpath: "dashboard",
      //   active: false,
      //   url: "/dashboard",
      // },
      // {
      //   text: "Meeting analytics",
      //   icon: null,
      //   hasAccess: true,
      //   rootpath: "users/expert",
      //   active: false,
      //   url: "/users/expert",
      // },
      // {
      //   text: "Subscription analytics",
      //   icon: null,
      //   hasAccess: true,
      //   rootpath: "users/expert",
      //   active: false,
      //   url: "/users/expert",
      // },
    ],
  },
  {
    text: "User Management",
    icon: UserIcon,
    hasAccess: hasAccess(PERMISSIONS.USER_MANAGEMENT),
    rootpath: "users",
    url: renderPath(),
    active: false,
    child: [],
  },
  {
    text: "Role Access", //Name of List Item
    icon: rolesIcon, //Icon of List Item
    hasAccess: hasAccess(PERMISSIONS.ROLE_ACCESS),
    rootpath: "roles", //RootPath used for selected view
    url: "/roles", //After Click where you want to redirect
    active: false,
    child: [],
  },
  {
    text: "Category Management",
    icon: DashboardIcon,
    hasAccess: hasAccess(PERMISSIONS.CATEGORY_MANAGEMENT),
    rootpath: "categories",
    url: "/categories",
    active: false,
    child: [],
  },
  {
    text: "Product Management",
    icon: DashboardIcon,
    hasAccess: hasAccess(PERMISSIONS.PRODUCT_MANAGEMENT),
    rootpath: "products",
    url: "/products",
    active: false,
    child: [],
  },
  {
    text: "Purchase Managment",
    icon: DashboardIcon,
    hasAccess: hasAccess(PERMISSIONS.PURCHASE_MANAGEMENT),
    rootpath: "purchase",
    url: "/purchase",
    active: false,
    child: [],
  },
  {
    text: "Review & Rating",
    icon: DashboardIcon,
    hasAccess: hasAccess(PERMISSIONS.REVIEW_RATING),
    rootpath: "reviewRating",
    url: "/reviewRating",
    active: false,
    child: [],
  },
  {
    text: "Point of Sale",
    icon: DashboardIcon,
    hasAccess: hasAccess(PERMISSIONS.POINT_OF_SALE),
    rootpath: "point-of-sale",
    url: "/point-of-sale",
    active: false,
    child: [],
  },
  {
    text: "Inventory",
    icon: DashboardIcon,
    hasAccess: hasAccess(PERMISSIONS.ORDER_MANAGEMENT),
    rootpath: "inventory",
    url: "/inventory",
    active: false,
    child: [],
  },
  {
    text: "Sales Management",
    icon: MeetingIcon,
    rootpath: "sales",
    hasAccess:
      hasAccess(PERMISSIONS.ORDER_MANAGEMENT) ||
      hasAccess(PERMISSIONS.TRANSACTION_MANAGEMENT),
    url: "/sales",
    active: false,
    child: [
      {
        text: "Order Management",
        icon: null,
        hasAccess: hasAccess(PERMISSIONS.ORDER_MANAGEMENT),
        rootpath: "orders",
        active: false,
        url: "/orders",
      },
      {
        text: "Transactions Management",
        icon: null,
        hasAccess: hasAccess(PERMISSIONS.TRANSACTION_MANAGEMENT),
        rootpath: "transactions",
        active: false,
        url: "/transactions",
      },
    ],
  },
  {
    text: "Banner Management",
    icon: TemplateIcon,
    hasAccess: hasAccess(PERMISSIONS.BANNER_MANAGEMENT),
    rootpath: "banners",
    url: "/banners",
    active: false,
    child: [],
  },
  {
    text: "Slider Management",
    icon: GuideIcon,
    hasAccess: hasAccess(PERMISSIONS.SLIDER_MANAGEMENT),
    rootpath: "sliders",
    url: "/sliders",
    active: false,
    child: [],
  },
  {
    text: "Task Management",
    icon: GuideIcon,
    hasAccess: hasAccess(PERMISSIONS.TASK_MANAGEMENT),
    rootpath: "tasks",
    url: "/tasks",
    active: false,
    child: [],
  },
  {
    text: "Newsletter & Inquirey",
    icon: DashboardIcon,
    hasAccess: hasAccess(PERMISSIONS.NEWSLETTER_INQUIREY),
    rootpath: "newsletter-inquirey",
    url: "/newsletter-inquirey",
    active: false,
    child: [],
  },
  {
    text: "Coupon Management",
    icon: GuideIcon,
    hasAccess: hasAccess(PERMISSIONS.COUPON_MANAGEMENT),
    rootpath: "coupon",
    url: "/coupon",
    active: false,
    child: [],
  },
  {
    text: "Services Management",
    icon: GuideIcon,
    hasAccess: hasAccess(PERMISSIONS.SERVICE_MANAGEMENT),
    rootpath: "services",
    url: "/services",
    active: false,
    child: [],
  },
  {
    text: "Services Bills",
    icon: GuideIcon,
    hasAccess: hasAccess(PERMISSIONS.SERVICE_BILLS),
    rootpath: "service-bills",
    url: "/service-bills/individual",
    active: false,
    child: [],
  },
  // {
  //     text:"Document Management",
  //     icon:DocumentChecked,
  //     rootpath:'documents',
  //     url:"/documents",
  //     active:false,
  //     child:[]
  // },
  // {
  //     text:"Transaction Management",
  //     icon:TransactionIcon,
  //     rootpath:'transactions',
  //     url:"/transactions",
  //     active:false,
  //     child:[]
  // },
  // {
  //     text:"Subscription Management",
  //     icon:SubscriptionIcon,
  //     rootpath:'subscriptions',
  //     url:"/subscriptions",
  //     active:false,
  //     child:[]
  // },
  {
    text: "Company Info",
    icon: CompanyInfoIcon,
    hasAccess: hasAccess(PERMISSIONS.CMS_MANAGEMENT),
    rootpath: "company",
    url: "/company",
    active: false,
    child: [
      {
        text: "About/Contact us",
        icon: null,
        hasAccess: hasAccess(PERMISSIONS.CMS_MANAGEMENT),
        rootpath: "company/about",
        active: false,
        url: "/company/about",
      },
      {
        text: "Privacy Policy",
        icon: null,
        hasAccess: hasAccess(PERMISSIONS.CMS_MANAGEMENT),
        rootpath: "company/privacy-policy",
        active: false,
        url: "/company/privacy-policy",
      },
      {
        text: "Terms of Use",
        icon: null,
        hasAccess: hasAccess(PERMISSIONS.CMS_MANAGEMENT),
        rootpath: "company/terms",
        active: false,
        url: "/company/terms",
      },
      {
        text: "FAQs",
        icon: null,
        hasAccess: hasAccess(PERMISSIONS.CMS_MANAGEMENT),
        rootpath: "company/faq",
        active: false,
        url: "/company/faq",
      },
    ],
  },
  {
    text: "Settings",
    icon: SettingIcon,
    hasAccess: hasAccess(PERMISSIONS.SETTINGS),
    rootpath: "settings",
    url: "/settings",
    active: false,
    child: [
      // {
      //     text:"Area",
      //     icon:null,
      //     rootpath:'setting/area',
      //     active:false,
      //     url:"/setting/area",
      // },
      {
        text: "City",
        icon: null,
        hasAccess: hasAccess(PERMISSIONS.SETTINGS),
        rootpath: "setting/city",
        active: false,
        url: "/setting/city",
      },
      {
        text: "State",
        icon: null,
        hasAccess: hasAccess(PERMISSIONS.SETTINGS),
        rootpath: "setting/state",
        active: false,
        url: "/setting/state",
      },
      {
        text: "Country",
        icon: null,
        hasAccess: hasAccess(PERMISSIONS.SETTINGS),
        rootpath: "setting/country",
        active: false,
        url: "/setting/country",
      },
    ],
  },
];

export { menu };
