import categoryDefaultImage from "./images/upload-default-image.png";
import GoogleIcon from './images/icons/google.svg';

export default {
  icons: {
    GoogleIcon
  },
  images: {
    CATEGORY_DEFAULT_IMAGE: categoryDefaultImage,
  },
};
