import { Box, Button, Paper, TextField } from "@material-ui/core";
import clsx from "clsx";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { FixedSizeList as List } from "react-window";
import "./css/autoComplete.css";

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
};

const DropdownItem = React.memo(
  ({ style, value, item, index, isSelected, onClick }) => {
    const itemRefs = useRef([]); // Array to hold refs for each dropdown item

    const highlightText = (text, highlight) => {
      const parts = text?.split(new RegExp(`(${highlight})`, "gi"));
      return (
        <>
          {parts?.map((part, index) =>
            part.toLowerCase() === highlight.toLowerCase() ? (
              <span key={index} style={{ backgroundColor: "yellow" }}>
                {part}
              </span>
            ) : (
              part
            )
          )}
        </>
      );
    };

    // useEffect(() => {
    //   if (isSelected) {
    //     console.log(itemRefs.current);
    //     //  Scroll the parent container to make the selected item visible
    //     // itemRefs.current.focus({preventScroll: true});
    //     // itemRefs.current.scrollIntoView({
    //     //   behavior: "smooth",
    //     //   block: "nearest",
    //     // });
    //   }
    // }, [itemRefs, isSelected]);

    return (
      <Box
        style={{ ...style, height: "auto" }}
        className={clsx({ selected: isSelected })}
        key={item.id}
        ref={(el) => (itemRefs.current = el)}
        padding="10px"
        tabIndex={-1}
        onClick={onClick}
      >
        {highlightText(item.name, value)} (
        {highlightText(item.secondary_name, value)})
      </Box>
    );
  }
);

const AutoCompleteInput = forwardRef(
  ({ value, options, disabled ,onChange, onSelection, onKeyDown }, ref) => {
    const textFieldRef = useRef();
    const searchBoxRef = useRef();
    const listRef = useRef();
    const debouncedValue = useDebounce(value, 300);
    const [selectedIndex, setSelectedIndex] = useState(-1); // Index of the currently highlighted item
    const [dropdownOptions, setDropdownOptions] = useState([]); // Index of the currently highlighted item
    const [inputFocused, setInputFocused] = useState(true); // Track input focus

    const handleChange = (e) => {
      onChange(e);
      setSelectedIndex(-1); // Reset index when typing
    };

    // Relevance sorting helper function
    const getRelevanceScore = useCallback((item, searchTerm) => {
      const name = item?.name?.toLowerCase();
      const secondaryName = item?.secondary_name?.toLowerCase();
      const search = searchTerm.toLowerCase();

      // Exact matches at the beginning of the name get the highest score
      if (name?.startsWith(search)) return 3;
      if (secondaryName?.startsWith(search)) return 2;

      // Partial matches get a lower score
      if (name?.includes(search)) return 1;
      if (secondaryName?.includes(search)) return 0;

      // No match
      return -1;
    }, []);

    const sortedOptions = useMemo(() => {
      return options
        ?.map((item) => ({
          ...item,
          relevance: getRelevanceScore(item, debouncedValue),
        }))
        ?.filter((item) => item.relevance >= 0)
        ?.sort((a, b) => b.relevance - a.relevance);
    }, [debouncedValue, options, getRelevanceScore]);

    useEffect(() => {
      setDropdownOptions(sortedOptions);
    }, [sortedOptions]);

    useImperativeHandle(
      ref,
      () => {
        return {
          textFieldRef: textFieldRef.current,
          searchBoxRef: searchBoxRef.current,
        };
      },
      []
    );

    const selectProduct = (index) => {
      onSelection(dropdownOptions[index]);
      setSelectedIndex(-1); // Reset after selection
      setInputFocused(true); // Return focus to input
      textFieldRef.current.focus();
    };

    const handleKeyDown = (e) => {
      if (onKeyDown) onKeyDown(e); // Call parent onKeyDown if provided
    
      if (e.code === "ArrowDown" && dropdownOptions.length > 0) {
        // Move focus to the next dropdown item
        setSelectedIndex((prevIndex) => {
          const nextIndex = prevIndex + 1 < dropdownOptions.length ? prevIndex + 1 : prevIndex;
          listRef.current.scrollToItem(nextIndex, "auto");
          return nextIndex;
        });
        setInputFocused(false);
      } else if (e.code === "ArrowUp") {
        setSelectedIndex((prevIndex) => {
          const nextIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
          listRef.current.scrollToItem(nextIndex, "auto");
          return nextIndex;
        });
      } else if (e.code === "Enter") {
        if (dropdownOptions[selectedIndex]) {
          onSelection(dropdownOptions[selectedIndex]);
          setSelectedIndex(-1); // Reset after selection
          setInputFocused(true); // Return focus to input
          textFieldRef.current.focus(); // Focus on text field
        }
      } else {
        setInputFocused(true); // Return focus to input on any other key press
      }
    };
    

    // const handleKeyDown = (e) => {
    //   if (onKeyDown) onKeyDown(e); // Call parent onKeyDown if provided

    //   if (e.code === "ArrowDown" && dropdownOptions.length > 0) {
    //     // Move focus to the first dropdown item
    //     if (selectedIndex + 1 < dropdownOptions.length) {
    //       setSelectedIndex((prevIndex) => {
    //         return prevIndex + 1;
    //       });

    //       setInputFocused(false);
    //     }
    //   } else if (e.code === "ArrowUp") {
    //     if (selectedIndex > 0) setSelectedIndex((prevIndex) => prevIndex - 1);
    //   } else if (e.code === "Enter") {
    //     if (dropdownOptions[selectedIndex]) {
    //       onSelection(dropdownOptions[selectedIndex]);
    //       setSelectedIndex(-1); // Reset after selection
    //       setInputFocused(true); // Return focus to input
    //       textFieldRef.current.focus(); // Focus on text field
    //     }
    //   } else {
    //     setInputFocused(true); // Return focus to input on any other key press
    //   }
    // };



    return (
      <Box display={"flex"}>
        <Box flex={1}>
          <TextField
            disabled={disabled}
            onKeyDown={handleKeyDown}
            inputRef={textFieldRef}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            placeholder="Select Product"
            value={value}
            onFocus={() => setInputFocused(true)}
          />
        </Box>
        <Box
          display={
            debouncedValue.length && dropdownOptions.length > 0
              ? "block"
              : "none"
          }
          style={{ padding: "0px 20px" }}
          width={"35%"}
          overflow={"auto"}
        >
          {/* <Paper ref={searchBoxRef}> */}
            <List
              height={600}
              ref={listRef}
              itemCount={dropdownOptions.length}
              itemSize={40}
              width={"100%"}
            >
              {({ index, style }) => (
                <>
                  <DropdownItem
                    value={debouncedValue}
                    style={style}
                    item={dropdownOptions[index]}
                    index={index}
                    isSelected={index === selectedIndex}
                    onClick={() => selectProduct(index)}
                  />
                </>
              )}
            </List>
          {/* </Paper> */}
        </Box>
      </Box>
    );
  }
);

export default AutoCompleteInput;
