import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Header from "../layouts/Header";
import {
  Box,
  Button,
  DialogContentText,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Select,
  Typography,
  useTheme,
} from "@material-ui/core";
import TextField from "../MuiComponent/TextField";
import ReactTable from "../MuiComponent/ReactTable";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCache,
  selectLoadingState,
  selectProductOfferData,
  selectProducts,
  selectPurchaseData,
} from "../store/selectors";
import {
  addProductOffer,
  deleteOfferProduct,
  fetchProductOffers,
  fetchProducts,
  fetchProductsProgressively,
  fetchPurchaseData,
  syncCacheData,
  updateProductOffer,
} from "../store/actions";
import { currency } from "../utils/utility";
import useGeneralStyle from "../assets/css/general";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Modal from "../MuiComponent/Modal";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import AutoCompleteInput from "../components/AutoCompleteInput";
import { useAuth } from "../providers/AuthProvider";
import queryString from "query-string";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { indexDB } from "../utils/indexDb";

const useStyles = makeStyles((theme2) => ({
  root: {
    width: "100%",
    marginTop: "25px",
    overflowX: "auto",
    backgroundColor: "white",
    border: "1px solid #caced5",
  },
  container: {
    height: "440px",
    width: "100%",
  },
  inputLabel: {
    color: "color",
    fontSize: "13px",
    padding: "10px 0px",
  },
  activeFilter: {
    backgroundColor: theme2.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
}));

function ProductOffers() {
  const theme = useTheme();
  const classes = useStyles();
  const tableRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useAuth();
  const autoCompleteRef = useRef();
  const globalClasses = useGeneralStyle();

  const { userData } = useSelector((state) => state.general);

  const tableLoader = useSelector(selectLoadingState("react_table_loader"));
  const { data, meta } = useSelector(selectProductOfferData);

  const [confirmation, setConfirmation] = useState(false);
  const [mode, setMode] = useState("add");
  const [selectedData, setselectedData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [searchProduct, setSearchProductKey] = useState("");

  const productList = useSelector(selectCache("products"));
  const [filter, setFilter] = useState({
    search_string: null,
  });

  const {
    values,
    touched,
    errors,
    submitForm,
    setValues,
    handleChange,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues: {
      products: [],
      product_id: "",
      offer_price: "",
      cart_value: "",
      status: "Y",
    },
    onSubmit: () => {
      try {
        if (mode == "add") {
          dispatch(
            addProductOffer({
              ...values,
              callback: () => {
                setConfirmation(!confirmation);
              },
            })
          );
        } else
          dispatch(
            updateProductOffer({
              id: values.id,
              product_id: values.product_id,
              offer_price: values.offer_price,
              cart_value: values.cart_value,
              status: values.status,
              callback: () => {
                setConfirmation(!confirmation);
              },
            })
          );
      } catch (er) {
        console.log("ERROR:", er);
      }
    },
  });

  useEffect(() => {
    dispatch(
      fetchProductsProgressively({
        business_id: auth.userData.business_id,
        pagination: true,
      })
    );
  }, []);

  const columns = useMemo(() => {
    return [
      {
        header: "DATE",
        key: "updated_at",
        type: "date",
        dateFormat: "DD MMM YYYY, hh:mm a",
      },
      {
        header: "PRODUCT",
        type: "custom_cell",
        cell: ({
          row: {
            original: { product },
          },
        }) => {
          return product.name;
        },
      },
      {
        header: "OFFER_PRICE",
        type: "custom_cell",
        cell: ({ row: { original: product } }) => {
          return currency({
            value: product.offer_price,
            currency: process.env.REACT_APP_RAZORPAY_CURRENCY || "INR",
          });
        },
      },
      {
        header: "CART VALUE",
        type: "custom_cell",
        cell: ({ row: { original: product } }) => {
          return currency({
            value: product.cart_value,
            currency: process.env.REACT_APP_RAZORPAY_CURRENCY || "INR",
          });
        },
      },
      {
        header: "STATUS",
        key: "status",
      },
      //   {
      //     header: "VIEW",
      //     type: "custom_cell",
      //     cell: ({ row: { original: product } }) => {
      //       return (
      //         <a
      //           className={globalClasses.link}
      //           style={{ color: "#2895c4" }}
      //           onClick={() => {
      //             console.log("product Data:", product);
      //             setBillData({ ...product });
      //             setMode("view");
      //           }}
      //         >
      //           View
      //         </a>
      //       );
      //     },
      //   },
      {
        header: "MANAGE",
        type: "custom_cell",
        cell: ({ row: { original: product } }) => {
          return (
            <>
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <React.Fragment>
                    <IconButton {...bindTrigger(popupState)}>
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        onClick={() => {
                          setselectedData(product);
                          setValues({
                            ...product,
                            products: [product.product],
                          });
                          setConfirmation(!confirmation);
                          setMode("edit");
                          popupState.setOpen(false);
                        }}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          if (product.status === "delivered") {
                            setConfirmation(true);
                          } else {
                            setOpenModal(true);
                          }
                          setselectedData(product);
                          popupState.setOpen(false);
                        }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
            </>
          );
        },
      },
    ];
  }, []);

  const onChangeHandler = useCallback((e) => {
    setSearchProductKey(e.target.value);
  }, []);

  useEffect(() => {
    indexDB.readData("products").then((result) => {
      dispatch(syncCacheData({ key: "products", data: result }));
    });
  }, []);

  const onKeyDownHandler = useCallback((e) => {
    if (e.key == "Enter") {
      keyPressCounter += 1;
      setKeyPressCounter(keyPressCounter);
      if (keyPressCounter > 1) handleKeyDown(e, "discount");
      else setKeyPressCounter(0);
    }
  }, []);

  const onSelectionHandler = useCallback(
    (selection) => {
      if (typeof selection === "object") {
        setSearchProductKey("");

        let { name, id: product_id } = selection;
        values.products[0] = { name, product_id, quantity: 1 };
        values.product_id = product_id;
        // values.products.push({ name, product_id, quantity: 1 });
        setValues({ ...values });
      }
    },
    [values]
  );

  const globalSearchHandler = useCallback((e) => {
    let params = queryString.parse(location.search);
    params.search_string = e.target.value;

    filter.search_string = e.target.value;
    setFilter({ ...filter });
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      tableRef.current.refreshDatatable();
    }, 300);

    Object.keys(filter).forEach((key) => {
      if (filter[key] == null || filter[key] == "") {
        delete filter[key];
      }
    });

    history.replace(location.pathname + "?" + `${new URLSearchParams(filter)}`);

    return () => clearTimeout(delayDebounceFn);
  }, [filter.search_string]);

  const getTableData = useCallback(
    (apiBody) => {
      console.log(
        "REACT TABLE PAGE INDEX MANUAL PAGINATION: GET API INVOKED: ",
        apiBody
      );

      dispatch(fetchProductOffers(apiBody));
    },
    [dispatch]
  );

  const ModalHeader = () => {
    return (
      <>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{mode == "add" ? "Create" : "Edit"} Offer</Box>
          <Box>
            <IconButton onClick={() => setConfirmation(!confirmation)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <Header>
        <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
          Product Offers Management
        </Typography>
      </Header>
      <Modal
        height="80vh"
        width="100%"
        // loading={loading}
        headerComponent={ModalHeader}
        status={confirmation}
        closeBtnText="cancel"
        saveBtnText="Submit"
        closeHandler={() => setConfirmation(!confirmation)}
        saveHandler={submitForm}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel className={globalClasses.inputLabel}>
                Select Product
              </InputLabel>
              <AutoCompleteInput
                value={searchProduct}
                onKeyDown={onKeyDownHandler}
                ref={autoCompleteRef}
                options={productList}
                onChange={onChangeHandler}
                onSelection={onSelectionHandler}
              />
              <FormHelperText error variant="outlined">
                {touched.products && errors.products}
              </FormHelperText>
              <Box>{values.products.map((item) => item.name)}</Box>
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={globalClasses.inputLabel}>
                Offer Price
              </InputLabel>
              <TextField
                id="offer_price"
                name="offer_price"
                type="number"
                min={1}
                value={values.offer_price}
                onChange={handleChange}
                onBlur={handleBlur}
                // error={
                //   addOrderForm.touched.name && addOrderForm.errors.name
                //     ? true
                //     : false
                // }
                // helperText={
                //   addOrderForm.touched.name && addOrderForm.errors.name
                // }
                // onChange={addOrderForm.handleChange}
                // onBlur={addOrderForm.handleBlur}
                // value={addOrderForm.values.name}
                variant="outlined"
                placeholder="Offer Price"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={globalClasses.inputLabel}>
                Cart Value
              </InputLabel>
              <TextField
                id="name"
                type="number"
                min={1}
                name="cart_value"
                value={values.cart_value}
                onChange={handleChange}
                onBlur={handleBlur}
                // error={
                //   addOrderForm.touched.name && addOrderForm.errors.name
                //     ? true
                //     : false
                // }
                // helperText={
                //   addOrderForm.touched.name && addOrderForm.errors.name
                // }
                // onChange={addOrderForm.handleChange}
                // onBlur={addOrderForm.handleBlur}
                // value={addOrderForm.values.name}
                variant="outlined"
                placeholder="Amount"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={globalClasses.inputLabel}>
                Status
              </InputLabel>
              <Select
                name="status"
                onChange={handleChange}
                value={values.status}
                fullWidth
                variant="outlined"
              >
                <MenuItem value="Y">Active</MenuItem>
                <MenuItem value="N">Inactive</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>
      <Grid container style={{ padding: theme.spacing(3) }}>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px 0px",
          }}
          item
          xs={5}
        >
          <Box style={{ paddingRight: "20px" }}>Search</Box>
          <TextField
            onChange={globalSearchHandler}
            variant="outlined"
            style={{ width: "100%" }}
            inputProps={{
              style: { padding: "12px", backgroundColor: "white" },
            }}
            placeholder="by Name"
          />
        </Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row-reverse",
            padding: "10px 0px",
          }}
          item
          xs={7}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              resetForm();
              if (
                _.isNull(userData.businessDetails) ||
                _.isNull(userData?.businessDetails?.account_details)
              )
                setBusinessCheck(true);
              else {
                setConfirmation(!confirmation);
              }
            }}
          >
            Create Offer
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <ReactTable
              ref={tableRef}
              fetchData={getTableData}
              isLoading={tableLoader}
              totalRecords={meta?.total}
              columns={columns}
              data={data}
              manualPagination={true}
            />
          </Paper>
        </Grid>
      </Grid>
      <Modal
        height="169px"
        width="388px"
        headerComponent={NewConfirmationModalHeader}
        status={openModal}
        // loading={actionApiStatus.isPending}
        closeBtnText="Cancel"
        saveBtnText="Yes"
        saveHandler={()=>{
          dispatch(deleteOfferProduct({...selectedData,callback:()=>{
            setOpenModal(!openModal)
          }}))
        }}
        closeHandler={() => {
          setOpenModal(!openModal);
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          Are you sure you want to delete this bill ?
        </Box>
      </Modal>
    </>
  );
}

export default ProductOffers;


const NewConfirmationModalHeader = () => {
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        style={{ fontSize: "14px" }}
      >
        <Box textAlign="center" style={{ fontWeight: 600 }} padding={1}>
          Confirm delete
        </Box>
      </Box>
    </>
  );
};