import { Box } from "@material-ui/core";
import React from "react";
import { RotatingLines } from "react-loader-spinner";

function DefaultLoader() {
  return (
    <Box textAlign={"center"} marginTop="50px">
      <RotatingLines
        visible={true}
        height="96"
        width="96"
        color="grey"
        strokeWidth="5"
        strokeColor="#fbc068"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </Box>
  );
}

export default DefaultLoader;
