import React, { useEffect, useState, useMemo, useCallback } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import queryString from "query-string";

// Debounce function
export const debounce = (fn, delay) => {
  let timeout;
  return (...args) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => fn(...args), delay);
  };
};

const useStyles = makeStyles((theme2) => ({
  container: {
    height: "70vh",
  },
}));

function Datatable(props) {
  const history = useHistory();
  const location = useLocation();
  const searchParams = useMemo(() => queryString.parse(location.search), [location.search]);
  const [page, setPage] = useState(searchParams.page ? searchParams.page - 1 : 0);
  const [rowsPerPage, setRowsPerPage] = useState(searchParams.page_size ? searchParams.page_size : 10);
  const classes = useStyles();
  const { getDataList, loading, tableHeader, totalRecords, children } = props;

  useEffect(() => {
    const storedPage = sessionStorage.getItem(`page_${location.key}`);
    const storedPageSize = sessionStorage.getItem(`page_size_${location.key}`);

    if (storedPage) setPage(Number(storedPage));
    if (storedPageSize) setRowsPerPage(Number(storedPageSize));
  }, [location.key]);

  useEffect(() => {
    getDataList(rowsPerPage, page + 1);
  }, [rowsPerPage, page, getDataList]);

  const handleChangePage = useCallback(
    debounce((event, newPage) => {
      if (newPage >= 0) {
        sessionStorage.setItem(`page_${location.key}`, newPage);
        searchParams.page = newPage + 1;
        history.replace(location.pathname + "?" + queryString.stringify(searchParams));

        getDataList(rowsPerPage, newPage);
        setPage(newPage);
      }
    }, 300),
    [location.search, rowsPerPage, getDataList]
  );

  const handleChangeRowsPerPage = useCallback(
    debounce((event) => {
      const newRowsPerPage = parseInt(event.target.value, 10);
      sessionStorage.setItem(`page_size_${location.key}`, newRowsPerPage);
      searchParams.page = 1;
      searchParams.page_size = newRowsPerPage;
      history.replace(location.pathname + "?" + queryString.stringify(searchParams));

      getDataList(newRowsPerPage, 1);
      setRowsPerPage(newRowsPerPage);
      setPage(0);
    }, 300),
    [location.search, getDataList]
  );

  const renderSkeleton = () => (
    <TableRow>
      {tableHeader.map((header, i) => (
        <TableCell
          key={i}
          align={header.align}
          style={{ minWidth: header.minWidth, fontWeight: 600 }}
          className={classes.tableHeadCell}
        >
          <Skeleton variant="rect" width={100} height={15} />
        </TableCell>
      ))}
    </TableRow>
  );

  return (
    <>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {tableHeader.map((header, i) => (
                <TableCell
                  key={i}
                  align={header.align}
                  // style={{
                  //   minWidth: header.minWidth,
                  //   width: header.width,
                  //   fontWeight: 700,
                  //   backgroundColor: "white",
                  // }}
                  className={classes.tableHeadCell}
                >
                  {header.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <>
                {Array.from({ length: 3 }).map((_, i) => renderSkeleton())}
              </>
            ) : (
              children
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

export default React.memo(Datatable);
