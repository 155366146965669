import {
  Box,
  Button,
  DialogContentText,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  useTheme,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useFormik } from "formik";
import _ from "lodash";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Datatable from "../../MuiComponent/Datatable";
import DynamicForm from "../../MuiComponent/DynamicForm";
import Modal from "../../MuiComponent/Modal";
import TextField from "../../MuiComponent/TextField";
import useGeneralStyle from "../../assets/css/general";
import { apiClient } from "../../config/apiClient";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import useCancelRequest from "../../hooks/useCancelToken";
import { useAuth } from "../../providers/AuthProvider";
import {
  addCustomer,
  fetchUserList,
  getFormStructure,
  setReducerData,
  updateCustomer,
} from "../../store/actions";
import {
  selectFormStructure,
  selectFormValidation,
  selectModalStatus,
  selectServiceCustomer,
} from "../../store/selectors";
import {
  customerDataValidation,
  generateYupSchema,
} from "../../validations/login-validate";

const tableHeader = [
  { id: "name", align: "left", label: "REGISTERED DATE", minWidth: 170 },
  {
    id: "population",
    label: "USER NAME",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "density",
    label: "MOBILE",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "density",
    label: "EMAIL",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  // {
  //   id: "detailed_profile",
  //   label: "DETAILED PROFILE",
  //   minWidth: 170,
  //   align: "left",
  //   format: (value) => value.toFixed(2),
  // },
  {
    id: "density",
    label: "MANAGE",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

const useStyles = makeStyles((theme2) => ({
  root: {
    width: "100%",
    marginTop: "25px",
    overflowX: "auto",
    backgroundColor: "white",
    border: "1px solid #caced5",
  },
  selectInput: {
    marginLeft: "20px",
    "& .MuiOutlinedInput-input": {
      padding: "12.5px 14px",
      width: "150px",
    },
  },
  container: {
    height: "440px",
    width: "100%",
  },
  activeFilter: {
    backgroundColor: theme2.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
}));

function ServiceCustomerDatatable({ userType }) {
  const classes = useStyles();
  const [search, setSearchKey] = useState(null);
  const [mode, setMode] = React.useState("add");
  const globalClasses = useGeneralStyle();
  const formStructure = useSelector(selectFormStructure("service_customers"));
  const dispatch = useDispatch();
  const getAllUserApiStatus = useCallbackStatus();
  const manageUserApiStatus = useCallbackStatus();
  const apiSource = useCancelRequest();
  const auth = useAuth();
  const history = useHistory();
  const { data, total: totalPages } = useSelector(selectServiceCustomer);
  const modal = useSelector(selectModalStatus);
  const formValidations = useSelector(selectFormValidation);
  const [filter, setFilter] = React.useState(
    _.isUndefined(history.location.state)
      ? "not_verified"
      : history.location.state.filter
  );
  const theme = useTheme();

  const ModalHeader = () => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ fontSize: "14px" }}
        >
          <Box textAlign="left" style={{ fontWeight: 600 }} padding={1}>
            {mode == "add" ? "Add" : "Update"} Customer
          </Box>
        </Box>
      </>
    );
  };

  const getAllUserList = (pageSize = 10, pageNumber = 1) => {
    dispatch(
      fetchUserList({
        page: pageNumber,
        page_size: pageSize,
        search_string: search,
        user_type: "service_customers",
        business_id: auth.userData.business_id,
      })
    );
  };

  useEffect(() => {
    dispatch(setReducerData({ data: false, property: "modal_status" }));

    const delayDebounceFn = setTimeout(() => {
      if (search !== null) {
        getAllUserList();
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const viewProfile = async (id, userType) => {
    await manageUserApiStatus.run(
      apiClient("POST", "common", "getuserprofilebyid", {
        body: {
          user_id: id,
          entity_type: userType,
        },
        shouldUseDefaultToken: false,
        cancelToken: apiSource.token,
        enableLogging: true,
      }).then((result) => {
        let {
          content: { data },
        } = result;
        history.replace("/users/view-profile", {
          filter: filter,
          userData: data,
          from: "/users",
          userType: userType,
        });
      })
    );
  };

  let formik = useFormik({
    initialValues: {
      name: "",
      mobile_number: "",
      email: "",
      form_input: {},
    },
    onSubmit: () => {
      if (mode === "edit") {
        dispatch(updateCustomer(values));
      } else {
        dispatch(addCustomer(values));
      }
    },
    validationSchema: formStructure
      ? customerDataValidation.concat(
          generateYupSchema(formStructure?.validations)
        )
      : customerDataValidation,
  });

  let {
    touched,
    errors,
    values,
    handleChange,
    setValues,
    resetForm,
    setErrors,
    handleBlur,
    submitForm,
  } = formik;

  useEffect(() => {
    console.log("formValidations:", formValidations);
    setErrors({ ...formValidations });
  }, [formValidations]);

  return (
    <>
      <Modal
        headerComponent={ModalHeader}
        height="400px"
        width="800px"
        loading={manageUserApiStatus.isPending}
        closeBtnText="Cancel"
        saveBtnText={mode == "add" ? "Add" : "Update"}
        saveHandler={submitForm}
        closeHandler={() => {
          resetForm();
          dispatch(setReducerData({ data: false, property: "modal_status" }));
        }}
        status={modal}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputLabel className={globalClasses.inputLabel}>Name</InputLabel>
              <TextField
                id="name"
                name="name"
                error={touched.name && errors.name}
                helperText={touched.name && errors.name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                FormHelperTextProps={{ style: { fontSize: "10px" } }}
                placeholder="Name"
                variant="outlined"
                // inputProps={{
                //   style: {
                //     padding: "10px",
                //     fontSize: "12px",
                //     backgroundColor: "white",
                //   },
                // }}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel className={globalClasses.inputLabel}>
                Mobile Number
              </InputLabel>
              <TextField
                id="mobile_number"
                name="mobile_number"
                error={touched.mobile_number && errors.mobile_number}
                helperText={touched.mobile_number && errors.mobile_number}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.mobile_number}
                FormHelperTextProps={{ style: { fontSize: "10px" } }}
                placeholder="Contact Number"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel className={globalClasses.inputLabel}>
                Email
              </InputLabel>
              <TextField
                id="email"
                name="email"
                error={touched.email && errors.email}
                helperText={touched.email && errors.email}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                FormHelperTextProps={{ style: { fontSize: "10px" } }}
                placeholder="Email"
                variant="outlined"
              />
            </Grid>

            <DynamicForm
              // loading={{
              //   productList: getProductsApiStatus.isPending,
              // }}
              // productList={productList}
              isLogicInComponent={true}
              formik={formik}
              formData={formStructure?.fields || []}
            >
              {({ jsx }) => {
                return (
                  <Grid item xs={6}>
                    {jsx}
                  </Grid>
                );
              }}
            </DynamicForm>
          </Grid>
        </DialogContentText>
      </Modal>
      <Grid container style={{ padding: theme.spacing(3) }}>
        <Grid
          style={{ display: "flex", alignItems: "center", padding: "10px 0px" }}
          item
          xs={12}
          sm={4}
        >
          <Box
            className={globalClasses.filterLabel}
            style={{ paddingRight: "20px" }}
          >
            Search
          </Box>
          <TextField
            onChange={(e) => setSearchKey(e.target.value)}
            variant="outlined"
            style={{ width: "100%" }}
            inputProps={{
              style: { padding: "12px", backgroundColor: "white" },
            }}
            placeholder="by Name"
          />
        </Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row-reverse",
            padding: "10px 0px",
          }}
          item
          xs={12}
          sm={8}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setMode("add");
              resetForm();
              dispatch(
                setReducerData({ data: true, property: "modal_status" })
              );
            }}
          >
            Add Customer
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <Datatable
              loading={getAllUserApiStatus.isPending}
              getDataList={getAllUserList}
              totalRecords={totalPages}
              tableHeader={tableHeader}
            >
              {data.map((row, index) => {
                return (
                  <TableRow key={index} hover>
                    <TableCell>
                      {moment(row.created_at).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>
                      {row.name} {row.last_name}
                    </TableCell>
                    <TableCell>{row.mobile_number}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    {/* <TableCell>
                      <Link
                        className={globalClasses.link}
                        onClick={() => viewProfile(row.id, row.user_type)}
                      >
                        View
                      </Link>
                    </TableCell> */}
                    <TableCell>
                      <Box>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <React.Fragment>
                              <IconButton {...bindTrigger(popupState)}>
                                <MoreHorizIcon />
                              </IconButton>
                              <Menu {...bindMenu(popupState)}>
                                <MenuItem
                                  onClick={() => {
                                    if (
                                      Object.keys(row.form_input).length > 0
                                    ) {
                                      setValues(row);
                                    } else {
                                      let rowObject = { ...row };

                                      if (formStructure)
                                        rowObject.form_input =
                                          formStructure?.fields.reduce(
                                            (acc, field) => {
                                              acc[field.name] = "";
                                              return acc;
                                            },
                                            {}
                                          );

                                      setValues(rowObject);
                                    }

                                    setMode("edit");
                                    popupState.setOpen(false);

                                    dispatch(
                                      setReducerData({
                                        data: true,
                                        property: "modal_status",
                                      })
                                    );
                                  }}
                                >
                                  Edit
                                </MenuItem>
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </Datatable>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default ServiceCustomerDatatable;
