import React, { useState } from "react";
import Header from "../layouts/Header";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Box, InputLabel, Typography } from "@material-ui/core";
import UserDataTable from "./users/UserDatatable";
import { Route, useHistory } from "react-router-dom";
// import UserProfile from './users/UserProfile';
import useGeneralStyle from "../assets/css/general";
import AdminDataTable from "./users/AdminDatatable";
import SuppliersDatatable from "./users/SuppliersDatatable";
import DeliveryStaffDatatable from "./users/DeliveryStaffDatatable";
import POSDataTable from "./users/POSDatatable";
import ServiceStaffDatatable from "./users/ServiceStaffDatatable";
import { useAuth } from "../providers/AuthProvider";
import { PERMISSIONS } from "../store/constants";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ServiceCustomerDatatable from "./users/ServiceCustomerDatatable";

function UserManagement() {
  const { user_type } = useParams();
  const [userType, setUserType] = useState("pos");
  const history = useHistory();
  const globalClasses = useGeneralStyle();
  const { hasAccess } = useAuth();

  const handleUserType = (e) => {
    setUserType(e.target.value);
    switch (e.target.value) {
      // case "admins":
      //   history.replace("/users/admins");
      //   break;
      case "suppliers":
        history.replace("/users/suppliers");
        break;
      // case "staff":
      //   history.replace("/users/delivery-staff");
      //   break;
      case "service-staff":
        history.replace("/users/service-staff");
        break;
      case "service-customers":
        history.replace("/users/service-customers");
        break;
      // case "pos":
      //   history.replace("/users/pos");
      //   break;
      default: //all
        history.replace("/users/walk-in");
        break;
    }
  };

  return (
    <>
      <Header>
        <Box flexGrow={1}>
          <InputLabel
            className={globalClasses.filterLabel}
            style={{ fontSize: "10px", color: "#6f6d6d" }}
          >
            Select User Type:
          </InputLabel>
          <Select
            style={{ fontSize: "14px" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={user_type}
            onChange={handleUserType}
          >
            {/* <MenuItem value="all">All Users</MenuItem>
            <MenuItem value="admins">All Admins</MenuItem> */}
            {hasAccess(PERMISSIONS.POINT_OF_SALE) && (
              <MenuItem value="walk-in">All Walkin</MenuItem>
            )}
            {hasAccess(PERMISSIONS.PURCHASE_MANAGEMENT) && (
              <MenuItem value="suppliers">All Suppliers</MenuItem>
            )}
            {hasAccess(PERMISSIONS.SERVICE_MANAGEMENT) && (
              <MenuItem value="service-customers">All Customers</MenuItem>
            )}
            {hasAccess(PERMISSIONS.STAFF_MANAGEMENT) && (
              <MenuItem value="service-staff">All Staff</MenuItem>
            )}
            {/* <MenuItem value="staff">All Delivery Staff</MenuItem>*/}
          </Select>
        </Box>
      </Header>

      {/* <Route exact path="/users/admins" component={AdminDataTable} />
      <Route exact path="/users/pos" component={POSDataTable} />
      <Route exact path="/users" component={UserDataTable} />
      <Route
        exact
        path="/users/delivery-staff"
        component={DeliveryStaffDatatable}
      /> */}

      {/* {JSON.stringify(userData?.permissions.includes(PERMISSIONS.STAFF_MANAGEMENT))} */}
      {hasAccess(PERMISSIONS.PURCHASE_MANAGEMENT) && (
        <Route exact path="/users/suppliers" component={SuppliersDatatable} />
      )}
      {hasAccess(PERMISSIONS.POINT_OF_SALE) && (
        <Route exact path="/users/walk-in" component={POSDataTable} />
      )}
      {hasAccess(PERMISSIONS.SERVICE_MANAGEMENT) && (
        <Route
          exact
          path="/users/service-customers"
          component={ServiceCustomerDatatable}
        />
      )}
      {hasAccess(PERMISSIONS.STAFF_MANAGEMENT) && (
        <Route
          exact
          path="/users/service-staff"
          component={ServiceStaffDatatable}
        />
      )}
    </>
  );
}

export default UserManagement;
