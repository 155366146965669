import { ThemeProvider } from "@material-ui/core";
import React, { Suspense } from "react";
import theme from "../layouts/theme";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "../providers/AuthProvider";
import { SnackbarProvider } from "notistack";
import DefaultLoader from "./DefaultLoader";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { store, persistor } from "../store";
// import { store } from "../store";
// import { PersistGate } from "redux-persist/integration/react";

const queryClient = new QueryClient();

function AppProvider(props) {
  return (
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <AuthProvider>
                <Router>
                  <Suspense fallback={<DefaultLoader />}>
                    {props.children}
                  </Suspense>
                </Router>
              </AuthProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </QueryClientProvider>
      {/* </PersistGate> */}
    </Provider>
  );
}

export default AppProvider;
