import { Box, Grid, TableCell, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { currency } from "../../utils/utility";
import { useAuth } from "../../providers/AuthProvider";
import Datatable from "../../MuiComponent/Datatable";

const useStyles = makeStyles((theme2) => ({
  activeFilter: {
    padding: "2px 0px",
    fontSize: "13px",
    color: "#63646c",
    fontWeight: 600,
  },
}));

function ServiceDetailPreview({ data }) {
  const classes = useStyles();
  const auth = useAuth();

  return (
    <>
      <Grid spacing={2} container>
        <Grid item xs={6}>
          <Box>
            <Box className={classes.activeFilter}>Name</Box>
            <Box>{data.name}</Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Box className={classes.activeFilter}>Labour</Box>
            <Box>
              {currency({
                currency: auth.userData.currency,
                value: data.labour,
              })}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Box className={classes.activeFilter}>Contact Number</Box>
            <Box>{data.mobile_number}</Box>
          </Box>
        </Grid>
      </Grid>
      <Datatable
        getDataList={()=>{}}
        totalRecords={10}
        loading={false}
        tableHeader={[
          {
            id: "name",
            align: "left",
            label: "NAME",
            minWidth: 30,
            width: 100,
          },
          {
            id: "amount",
            align: "left",
            label: "AMOUNT",
            minWidth: 30,
            width: 100,
          },
          {
            id: "quantity",
            align: "left",
            label: "QUANTITY",
            minWidth: 30,
            width: 100,
          },
          {
            id: "amount",
            align: "left",
            label: "TOTAL",
            minWidth: 30,
            width: 100,
          },
        ]}
      >
        {data?.services.map((data, index) => {
          return (
            <TableRow key={index}>
              <TableCell>{data.name}</TableCell>
              <TableCell>{data.amount}</TableCell>
              <TableCell>{parseFloat(data.quantity)}</TableCell>
              <TableCell>
                {currency({
                  currency: auth.userData.currency,
                  value: data.total_amount,
                })}
              </TableCell>
            </TableRow>
          );
        })}
      </Datatable>
    </>
  );
}

export default ServiceDetailPreview;
