import { combineReducers } from "redux";
import CategoryReducer from "./category/reducer";
import GeneralReducer from "./user/reducer";
import BillingReducer from "./reducers/BillingReducer";

export const rootReducer = combineReducers({
  general: GeneralReducer,
  category: CategoryReducer,
  billing: BillingReducer,
});
