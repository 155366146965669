import * as React from "react";
import "./css/react-table.css";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button, TablePagination } from "@material-ui/core";
import moment from "moment";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import queryString from "query-string";
import _, { ceil } from "lodash";
import { Skeleton } from "@material-ui/lab";
import "./css/react-table.css";

const columnHelper = createColumnHelper();

// *********************************************************************

//Sample Column Structure

// const columns = [
//   columnHelper.accessor("firstName", {
//     cell: (info) => info.getValue(),
//   }),
//   columnHelper.accessor((row) => row.lastName, {
//     id: "lastName",
//     cell: (info) => <i>{info.getValue()}</i>,
//     header: () => <span>Last Name</span>,
//   }),
//   columnHelper.accessor("age", {
//     header: () => "Age",
//     cell: (info) => info.renderValue(),
//   }),
//   columnHelper.accessor("visits", {
//     header: () => <span>Visits</span>,
//   }),
//   columnHelper.accessor("status", {
//     header: "Status",
//   }),
//   columnHelper.accessor("progress", {
//     header: "Profile Progress",
//   }),
// ];

// *********************************************************************

const ReactTable = React.forwardRef(
  (
    {
      data,
      columns,
      totalRecords,
      isLoading = true,
      manualPagination = true,
      fetchData,
    },
    ref
  ) => {
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [pagination, setPagination] = React.useState({
      pageIndex: parseInt(searchParams.get("page")) || 1, //initial page index
      pageSize: parseInt(searchParams.get("pageSize")) || 10, //default page size
    });

    React.useImperativeHandle(ref, () => {
      return {
        table,
        refreshDatatable: loadPage,
      };
    });

    const buildColumns = React.useMemo(() => {
      const renderColumnData = (column) => {
        switch (column.type) {
          case "date":
            return columnHelper.accessor(column.key, {
              header: column.header,
              cell: (info) =>
                moment(info.renderValue()).format(column.dateFormat),
            });

          case "custom_cell":
            return columnHelper.accessor(column.key, {
              header: column.header,
              cell: column.cell,
            });

          case "date":
            return columnHelper.accessor(column.key, {
              header: column.header,
              cell: (info) =>
                moment(info.renderValue()).format(column.dateFormat),
            });

          default:
            return columnHelper.accessor(column.key, {
              header: column.header,
              cell: (info) => info.renderValue(),
            });
        }
      };

      return columns.map((column) => {
        return renderColumnData(column);
      });
    }, [columns]);

    const table = useReactTable({
      data,
      columns: buildColumns,
      manualPagination: manualPagination,
      rowCount: manualPagination ? totalRecords : data.length,
      state: {
        pagination,
      },
      initialState: {
        pagination,
      },
      autoResetPageIndex: false,
      getCoreRowModel: getCoreRowModel(),
      getPaginationRowModel: getPaginationRowModel(), //load client-side pagination code
      onPaginationChange: setPagination, //update the pagination state when internal APIs mutate the pagination state
    });

    const loadPage = () => {
      let finalParams = _.omitBy(queryString.parse(location.search), _.isNull);
      console.log("LOCATION SEARCH: ", location.search, finalParams);
      history.replace(
        location.pathname +
          "?" +
          new URLSearchParams({
            ...finalParams,
            page: pagination.pageIndex,
            pageSize: pagination.pageSize,
          })
      );

      if (manualPagination == true) {
        console.log(
          "REACT TABLE PAGE INDEX MANUAL PAGINATION: MANU",
          finalParams
        );

        fetchData({
          ...finalParams,
          page: pagination.pageIndex,
          pageSize: pagination.pageSize,
        });
      }
    };

    React.useEffect(() => {
      loadPage();
    }, [pagination.pageIndex, pagination.pageSize]);

    return (
      <div className="p-2">
        <table
          id="react-table"
          border={0}
          style={{ width: "100%", borderCollapse: "collapse" }}
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {isLoading
              ? table.getHeaderGroups().map((headerGroup) => (
                  <React.Fragment key={headerGroup.id}>
                    {[1, 2, 3, 4, 5, 6, 7].map((item) => {
                      return (
                        <tr key={item}>
                          {headerGroup.headers.map((header) => (
                            <th key={header.id}>
                              <Skeleton height={30} />
                            </th>
                          ))}
                        </tr>
                      );
                    })}
                  </React.Fragment>
                ))
              : table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
          </tbody>
        </table>
        {!isLoading && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalRecords}
            rowsPerPage={pagination.pageSize}
            page={pagination.pageIndex-1}
            onPageChange={(event, newPage) => {
              setPagination({
                pageIndex: newPage+1,
                pageSize: pagination.pageSize,
              });
            }}
            onRowsPerPageChange={(e) => {
              setPagination({
                pageIndex: 1,
                pageSize: parseInt(e.target.value),
              });
            }}
          />
        )}
        {/* {!isLoading && (
          <>
            <Button
              onClick={() => {
                table.setPageIndex(1);
              }}
              disabled={table.getState().pagination.pageIndex == 1}
            >
              {"<<"}
            </Button>
            <Button
              onClick={() => {
                if (table.getState().pagination.pageIndex > 1)
                  table.previousPage();
              }}
              disabled={table.getState().pagination.pageIndex == 1}
            >
              {"<"}
            </Button>
            <Button
              onClick={() => table.nextPage()}
              disabled={
                ceil(totalRecords / pagination.pageSize) ===
                pagination.pageIndex
              }
              // disabled={!table.getCanNextPage()}
            >
              {">"}
            </Button>
            <Button
              onClick={() => table.lastPage()}
              disabled={
                ceil(totalRecords / pagination.pageSize) ===
                pagination.pageIndex
              }
              // disabled={!table.getCanNextPage()}
            >
              {">>"}
            </Button>

            <select
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                setPagination({
                  pageIndex: 1,
                  pageSize: parseInt(e.target.value),
                });
                // table.setPageIndex(Number(1));
                // table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </>
        )} */}
      </div>
    );
  }
);

export default ReactTable;
