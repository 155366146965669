import {
  Box,
  Button,
  DialogContentText,
  Grid,
  IconButton,
  InputLabel,
  useTheme,
  Select,
  MenuItem,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import _, { property } from "lodash";
import moment from "moment";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import QRCode from "react-qr-code";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useAuth } from "../../providers/AuthProvider";
import { currency } from "../../utils/utility";
import "./MiniBillFormat.css";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "../../MuiComponent/Modal";
import useGeneralStyle from "../../assets/css/general";
import { AntSwitch } from "../category/CategoryList";
import { useFormik } from "formik";
import { updateConfigurations } from "../../store/actions";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import TextField from "../../MuiComponent/TextField";
import { httpClient } from "../../config/httpClient";
var converter = require("number-to-words");

const MiniBillEcommerceFormat = forwardRef(({ data, onBack }, ref) => {
  const theme = useTheme();
  const { userData } = useAuth();
  const [modalStatus, setModalStatus] = useState(false);
  const globalClasses = useGeneralStyle();
  const dispatch = useDispatch();
  const fetchApiStatus = useCallbackStatus();
  const updateApiStatus = useCallbackStatus();
  const [modal, setModal] = React.useState(false);
  const [deliveryStaff, setDeliveryStaff] = useState([]);

  const {
    userData: { businessDetails },
  } = useSelector((state) => state.general);

  const {
    personal_name,
    mobile_number,
    address,
    email,
    business_name,
    footer,
    language,
    mic_translation,
  } = businessDetails?.business_configurations?.bill_configurations;

  function printdiv(elem) {
    var header_str =
      "<html><head><title>" + document.title + "</title></head><body>";
    var footer_str = "</body></html>";
    var new_str = document.getElementById(elem).innerHTML;
    var old_str = document.body.innerHTML;
    document.body.innerHTML = header_str + new_str + footer_str;
    window.print();
    document.body.innerHTML = old_str;
    // return false;
  }
  //   let {
  //     state: { data },
  //   } = location;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      history.back();
    },
  });

  useImperativeHandle(
    ref,
    () => {
      return {
        handlePrint,
      };
    },
    []
  );

  const orderStatusForm = useFormik({
    initialValues: {
      status: "placed",
      tracking_id: "",
      delivery_boy_id: "",
      reason: "",
    },
    onSubmit: () => {
      updateStatus();
    },
  });

  const { values, setFieldValue, submitForm } = useFormik({
    initialValues: {
      personal_name,
      business_name,
      address,
      email,
      mobile_number,
      footer,
      language,
      mic_translation,
    },
    onSubmit: () => {
      dispatch(
        updateConfigurations({
          key: "bill_configurations",
          value: values,
        })
      );
    },
  });

  const updateStatus = async () => {
    await updateApiStatus.run(
      httpClient("POST", "/partner/updatedStatus", {
        body: {
          status: orderStatusForm.values.status,
          id: data.cart_id,
          tracking_id: orderStatusForm.values.tracking_id,
          delivery_boy_id: orderStatusForm.values.delivery_boy_id,
        },
      })
        .then((result) => {
          console.log("Order", result);
          setModal(false);
          onBack();
        })
        .catch((er) => {
          console.log("ERROR ", er);
        })
    );
  };

  const getDeliveryStaff = async () => {
    await fetchApiStatus.run(
      httpClient("POST", `/partner/getUsers`, {
        body: {
          user_type: "delivery_staff",
        },
      }).then((result) => {
        const {
          data: { data },
        } = result;

        setDeliveryStaff(data);
      })
    );
  };

  useEffect(() => {
    getDeliveryStaff();
  }, []);

  const ModalHeader = () => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ fontSize: "14px" }}
        >
          <Box textAlign="left" style={{ fontWeight: 600 }} padding={1}>
            Bill Settings
          </Box>
          <IconButton
            style={{ position: "absolute", right: "20px" }}
            onClick={() => {
              setModalStatus(!modalStatus);
            }}
          >
            <CloseIcon size="small" />
          </IconButton>
        </Box>
      </>
    );
  };

  const handleSwitch = (e) => {
    setFieldValue(e.target.name, e.target.checked);
  };

  return (
    <>
      <Modal
        role="add-sales-dialog"
        headerComponent={ModalHeader}
        height="320px"
        // buttonAlign="center"
        width="100%"
        closeBtnText="Cancel"
        saveBtnText="Proceed"
        // loading={actionApiStatus.isPending}
        saveHandler={() => {
          setModalStatus(!modalStatus);
          submitForm();
        }}
        closeHandler={() => {
          setModalStatus(!modalStatus);
        }}
        status={modalStatus}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box alignItems={"center"} display={"flex"}>
                <Box flex={1} component={"span"}>
                  Email
                </Box>
                <AntSwitch
                  checked={values.email}
                  onChange={handleSwitch}
                  name="email"
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box alignItems={"center"} display={"flex"}>
                <Box flex={1} component={"span"}>
                  Address
                </Box>
                <AntSwitch
                  checked={values.address}
                  onChange={handleSwitch}
                  name="address"
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box alignItems={"center"} display={"flex"}>
                <Box flex={1} component={"span"}>
                  Footer
                </Box>
                <AntSwitch
                  checked={values.footer}
                  onChange={handleSwitch}
                  name="footer"
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box alignItems={"center"} display={"flex"}>
                <Box flex={1} component={"span"}>
                  Business Name
                </Box>
                <AntSwitch
                  checked={values.business_name}
                  onChange={handleSwitch}
                  name="business_name"
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box alignItems={"center"} display={"flex"}>
                <Box flex={1} component={"span"}>
                  Personal Name
                </Box>
                <AntSwitch
                  checked={values.personal_name}
                  onChange={handleSwitch}
                  name="personal_name"
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box alignItems={"center"} display={"flex"}>
                <Box flex={1} component={"span"}>
                  Mobile Number
                </Box>
                <AntSwitch
                  checked={values.mobile_number}
                  onChange={handleSwitch}
                  name="mobile_number"
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box alignItems={"center"} display={"flex"}>
                <Box flex={1} component={"span"}>
                  Primary Language
                </Box>
                <AntSwitch
                  checked={values.language == "primary"}
                  onChange={(e) => {
                    setFieldValue(
                      e.target.name,
                      e.target.checked ? "primary" : "secondary"
                    );
                  }}
                  name="language"
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>
      <Modal
        title="Update Status"
        height="300px"
        maxWidth="md"
        width="45%"
        closeBtnText="close"
        saveBtnText="save"
        saveHandler={orderStatusForm.submitForm}
        loading={updateApiStatus.isPending}
        closeHandler={() => {
          setModal(!modal);
        }}
        status={modal}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Status
                </InputLabel>
                <Select
                  value={orderStatusForm.values.status}
                  variant="outlined"
                  fullWidth
                  onChange={(event) => {
                    orderStatusForm.setFieldValue("status", event.target.value);
                  }}
                >
                  <MenuItem disabled value="placed">
                    Placed
                  </MenuItem>
                  <MenuItem value="shipped">Shipped</MenuItem>
                  <MenuItem value="delivered">Delivered</MenuItem>
                  <MenuItem value="cancelled">Cancelled</MenuItem>
                </Select>
              </Box>
              <Box>
                {orderStatusForm.values.status == "shipped" && (
                  <>
                    <InputLabel className={globalClasses.inputLabel}>
                      Delivery Boy
                    </InputLabel>
                    <Select
                      name="delivery_boy_id"
                      id="delivery_boy_id"
                      value={orderStatusForm.values.delivery_boy_id}
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        orderStatusForm.setFieldValue(
                          "delivery_boy_id",
                          event.target.value
                        );
                      }}
                    >
                      <MenuItem disabled value="">
                        Select
                      </MenuItem>
                      {deliveryStaff.map((deliveryBoy) => {
                        return (
                          <MenuItem value={deliveryBoy.id}>
                            {deliveryBoy.name} {deliveryBoy.last_name} {}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <InputLabel className={globalClasses.inputLabel}>
                      Traking Id
                    </InputLabel>

                    <TextField
                      name="tracking_id"
                      placeholder="Traking Id"
                      error={
                        orderStatusForm.touched.tracking_id &&
                        orderStatusForm.errors.tracking_id
                      }
                      helperText={
                        orderStatusForm.touched.tracking_id &&
                        orderStatusForm.errors.tracking_id
                      }
                      onChange={orderStatusForm.handleChange}
                      onBlur={orderStatusForm.handleBlur}
                      value={orderStatusForm.values.tracking_id}
                      variant="outlined"
                    />
                  </>
                )}
                {values.status == "cancelled" && (
                  <>
                    <InputLabel className={globalClasses.inputLabel}>
                      Reason
                    </InputLabel>
                    <TextField
                      name="reason"
                      placeholder="Reason"
                      // error={touched.note && errors.note}
                      // helperText={touched.note && errors.note}
                      // onChange={handleChange}
                      // onBlur={handleBlur}
                      // value={values.note}
                      // disabled={!isEditMode}
                      multiline
                      variant="outlined"
                    />
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>

      <Grid container style={{ padding: theme.spacing(3) }}>
        <Grid item xs={12}>
          <Box display={"flex"}>
            <Box flex={1}>
              <Button
                onClick={() => {
                  onBack();
                }}
              >
                <ArrowBackIosIcon /> Back
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setModal(!modal);
                }}
              >
                Update Status
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setModalStatus(!modalStatus);
                }}
              >
                Settings
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Box ref={componentRef} id="printable_div_id">
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ backgroundColor: "white", padding: "10px" }}>
                  <table
                    style={{
                      width: "80mm",
                      borderCollapse: "collapse",
                    }}
                  >
                    {personal_name && (
                      <tr>
                        <th colSpan={2}>
                          {_.upperCase(
                            `${userData.name} ${userData.last_name}`
                          )}
                        </th>
                      </tr>
                    )}
                    {business_name && (
                      <tr>
                        <th colSpan={2}>{_.upperCase(businessDetails.name)}</th>
                      </tr>
                    )}

                    {address && (
                      <tr>
                        <th style={{ fontSize: "12px" }} colSpan={2}>
                          Address: {businessDetails.address}
                          <div>
                            {businessDetails.city_name +
                              ", " +
                              businessDetails.state_name +
                              " (" +
                              businessDetails.pincode +
                              ")"}
                          </div>
                        </th>
                      </tr>
                    )}

                    <tr>
                      <th style={{ fontSize: "12px" }} colSpan={2}>
                        {/* GSTIN: 24BCYPR2433R1ZN
                          <br />
                          FSSAI: 10721021000240
                          <br /> */}
                        {email && (
                          <>
                            Email: {businessDetails.email} <br />
                          </>
                        )}
                        Mobile: {businessDetails.contact_number}
                      </th>
                    </tr>

                    <tr>
                      <th
                        className="pos_table_content"
                        colSpan={2}
                        style={{ textAlign: "left" }}
                      >
                        <hr />
                        Order ID: {data.cart_id}
                      </th>
                    </tr>
                    <tr>
                      <th
                        className="pos_table_content"
                        colSpan={2}
                        style={{ textAlign: "left" }}
                      >
                        <hr />
                        Name: {data?.name}
                      </th>
                    </tr>

                    <tr>
                      <th
                        className="pos_table_content"
                        colSpan={2}
                        style={{ textAlign: "left" }}
                      >
                        <hr />
                        Phone Number: {data?.mobile}
                      </th>
                    </tr>
                    <tr>
                      <th
                        className="pos_table_content"
                        colSpan={2}
                        style={{ textAlign: "left" }}
                      >
                        <hr />
                        Billing Address: <br />
                        {data?.billing_address?.line1}
                        <br />
                        {data?.billing_address?.line2} (
                        {data?.billing_address?.pincode})
                        <br />
                        {data?.billing_address?.city}{" "}
                        {data?.billing_address?.state}{" "}
                        {data?.billing_address?.country}
                      </th>
                    </tr>
                    <tr>
                      <th
                        className="pos_table_content"
                        colSpan={2}
                        style={{ textAlign: "left" }}
                      >
                        <hr />
                        <div style={{ display: "flex" }}>
                          <div style={{ flex: 1 }}>
                            Date: {moment(data.created_at).format("DD/MM/YYYY")}
                          </div>
                          <div>{moment(data.created_at).format("hh:mm a")}</div>
                        </div>
                      </th>
                    </tr>
                    <tr className="pos_table_content">
                      <td colSpan={2} style={{ textAlign: "left" }}>
                        <hr />
                        <table style={{ width: "100%" }}>
                          <tr>
                            <th>No.</th>
                            <th>Product.</th>
                            <th>Qty.</th>
                            <th>Price</th>
                            <th>Amount</th>
                          </tr>
                          <tr>
                            <td colSpan={5}>
                              <hr />
                            </td>
                          </tr>
                          {data.items.map((item, index) => (
                            <>
                              <tr>
                                <td colSpan={4}>
                                  {language == "primary"
                                    ? item.name
                                    : item.secondary_name || item.name}{" "}
                                  {Object.keys(item.options).length > 0 &&
                                    `[${Object.keys(item.options)
                                      .map((key) => {
                                        return `${key}: ${item.options[key].value}`;
                                      })
                                      .join(", ")}]`}
                                </td>
                              </tr>
                              <tr>
                                <td width="70px">{index + 1}</td>
                                <td width="70px"></td>
                                <td width="70px">{item.quantity}</td>
                                <td width="70px">
                                  {currency({
                                    value: item.product_price,
                                    currency: userData.currency,
                                  })}
                                </td>
                                <td width="70px">
                                  {currency({
                                    value: item.total_price,
                                    currency: userData.currency,
                                  })}
                                </td>
                              </tr>
                            </>
                          ))}
                        </table>
                      </td>
                    </tr>
                    <tr className="pos_table_content">
                      <td>
                        <hr />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <b>Total Quantity:</b>{" "}
                            {data.items.reduce((acc, item) => {
                              acc += item.quantity;
                              return acc;
                            }, 0)}
                          </div>
                          <div>
                            <b>Total Amount:</b>{" "}
                            {currency({
                              value: data.total_payable,
                              currency: userData.currency,
                            })}
                          </div>
                        </div>
                      </td>
                    </tr>
                    {footer && (
                      <tr className="pos_table_content">
                        <td
                          colSpan={2}
                          style={{
                            textAlign: "center",
                            padding: "12px",
                            fontWeight: "bold",
                          }}
                        >
                          Thanks For Visiting{" "}
                          {_.capitalize(businessDetails.name)}
                          <div>
                            {data.cart_id && (
                              <QRCode
                                style={{ marginTop: "10px", height: "30px" }}
                                value={data.cart_id}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    )}
                  </table>
                </div>
              </Box>
            </Box>
          </Box>
          <Box textAlign={"end"} marginRight={"10px"}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                handlePrint();

                // var element = document.getElementById("printable_div_id");
                // html2pdf(element);

                // printdiv("printable_div_id");
                // window.print();
              }}
            >
              Print
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
});

MiniBillEcommerceFormat.defaultProps = {
  data: {
    items: [],
  },
};

export default MiniBillEcommerceFormat;
