import thunk from "redux-thunk";
import { rootReducer } from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./rootSaga";
// import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import autoMergeLevel1 from "redux-persist/lib/stateReconciler/autoMergeLevel1";

const sagaMiddleware = createSagaMiddleware();

let middlewares = [thunk, sagaMiddleware];

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
};

// const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = configureStore({
  // reducer: persistedReducer,
  reducer: rootReducer,
  middleware: middlewares,
  devTools: true,
});

// let persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

// export { store, persistor };
export { store };

export * from "./constants";
